import React, { useState, useEffect } from 'react';
import axiosInstance from '../utils/auth';
import { addFederalHolidays } from './usFederalHolidays'; 
import { useNavigate } from 'react-router-dom';

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

const RoomAvailabilityTable = () => {
    const [rooms, setRooms] = useState([]);
    const [sites, setSites] = useState([]); // Add state for sites
    const [selectedSite, setSelectedSite] = useState(null);
    const [selectedRoom, setSelectedRoom] = useState(null);
    const [availability, setAvailability] = useState({});
    const [holidays, setHolidays] = useState([]);
    const [newHolidayName, setNewHolidayName] = useState('');
    const [newHolidayDate, setNewHolidayDate] = useState('');
    const [bulkStartTime, setBulkStartTime] = useState(''); // Add bulk start time
    const [bulkEndTime, setBulkEndTime] = useState('');     // Add bulk end time
    const navigate = useNavigate();

    useEffect(() => {
        fetchRooms();
        fetchSites(); // Fetch sites
        fetchHolidays();
    }, []);

    const fetchRooms = async () => {
        try {
            const response = await axiosInstance.get('/api/rooms_list/');
            const rooms = response.data;
            setRooms(rooms);

            const availabilityData = {};
            for (const room of rooms) {
                const roomAvailability = await fetchRoomAvailability(room.id);
                availabilityData[room.id] = roomAvailability;
            }

            setAvailability(availabilityData);
        } catch (error) {
            console.error('Error fetching rooms:', error);
        }
    };

    const fetchSites = async () => {
        try {
            const response = await axiosInstance.get('/api/sites/');
            setSites(response.data); // Set the site data
        } catch (error) {
            console.error('Error fetching sites:', error);
        }
    };

    const fetchRoomAvailability = async (roomId) => {
        try {
            const response = await axiosInstance.get(`/api/availabilities/?room=${roomId}`);
            const availabilityByDay = {};
            for (const day of daysOfWeek) {
                const lowerCaseDay = day.toLowerCase();
                availabilityByDay[day] = response.data.find(avail => avail.day_of_week === lowerCaseDay) || { start_time: '', end_time: '' };
            }
            return availabilityByDay;
        } catch (error) {
            console.error('Error fetching availability:', error);
        }
    };

    const fetchHolidays = async () => {
        try {
            const response = await axiosInstance.get('/api/holidays/');
            setHolidays(response.data);
        } catch (error) {
            console.error('Error fetching holidays:', error);
        }
    };

    const handleTimeChange = (roomId, day, timeType, value) => {
        const newAvailability = { ...availability };
        newAvailability[roomId][day][timeType] = value;
        setAvailability(newAvailability);
    };

    const handleBulkTimeChange = (start, end, applyTo) => {
        if (selectedRoom && selectedSite) {
            const newAvailability = { ...availability };
            applyTo.forEach((day) => {
                newAvailability[selectedRoom][day] = { start_time: start, end_time: end };
            });
            setAvailability(newAvailability);
        } else {
            alert('Please select a room and site.');
        }
    };

    const handleSave = async (roomId) => {
        try {
            const roomAvailability = daysOfWeek.map(day => {
                const { start_time, end_time } = availability[roomId]?.[day] || {};
                return {
                    room: roomId,
                    day_of_week: day.toLowerCase(),
                    start_time,
                    end_time,
                };
            }).filter(avail => avail.start_time && avail.end_time);

            if (roomAvailability.length > 0) {
                await axiosInstance.post('/api/availabilities/bulk/', { availabilities: roomAvailability });
                alert('Availability saved successfully!');
            }
        } catch (error) {
            console.error('Error saving availability:', error);
        }
    };

    const handleAddHoliday = async (date, name) => {
        try {
            // Check if the holiday already exists in the holidays state
            const existingHoliday = holidays.find(holiday => holiday.date === date);
    
            if (existingHoliday) {
                alert('Holiday with this date already exists!');
                return; // Exit the function if the holiday exists
            }
    
            // If the holiday does not exist, add it
            const response = await axiosInstance.post('/api/holidays/', { date, name });
            setHolidays([...holidays, response.data]); // Update the state with the new holiday
            alert('Holiday added successfully!');
        } catch (error) {
            console.error('Error adding holiday:', error);
        }
    };    

     // Function to handle adding US Federal Holidays (This Year and Next Year)
    const handleAddUsFederalHolidays = async () => {
        try {
            const existingHolidayDates = holidays.map(holiday => holiday.date);
            const usFederalHolidays = addFederalHolidays(); // This returns US federal holidays for the current and next year.
            const newHolidays = usFederalHolidays.filter(
                holiday => !existingHolidayDates.includes(holiday.date)
            );

            if (newHolidays.length > 0) {
                try {
                    for (const holiday of newHolidays) {
                        await handleAddHoliday(holiday.date, holiday.name); // Pass both date and name
                    }
                    alert('New US Federal Holidays added successfully!');
                    fetchHolidays(); // Refresh the holidays list after adding
                } catch (error) {
                    console.error('Error adding US Federal holidays:', error.response?.data || error.message);
                }
            } else {
                alert('No new holidays to add.');
            }
        } catch (error) {
            console.error('Error adding federal holidays:', error);
        }
    };

    const handleRemoveHoliday = async (id) => {
        try {
            await axiosInstance.delete(`/api/holidays/${id}/`);
            setHolidays(holidays.filter(holiday => holiday.id !== id));
        } catch (error) {
            console.error('Error removing holiday:', error.response?.data || error.message);
        }
    };

    return (
        <div>
            <h2>Room Availability Management</h2>
            <div>
                <label>Select Site:</label>
                <select onChange={(e) => setSelectedSite(e.target.value)}>
                    <option value="">-- Select a Site --</option>
                    {sites.map(site => <option key={site.id} value={site.id}>{site.name}</option>)}
                </select>
            </div>

            <div>
                <label>Select Room:</label>
                <select onChange={(e) => setSelectedRoom(e.target.value)}>
                    <option value="">-- Select a Room --</option>
                    {/* If a site is selected, filter by site, else show all rooms */}
                    {rooms
                        .filter(room => selectedSite === "" || room.site.id === parseInt(selectedSite))
                        .map(room => (
                            <option key={room.id} value={room.id}>{room.name}</option>
                        ))}
                </select>
            </div>

            <div>
                <label>Bulk Start Time:</label>
                <input type="time" value={bulkStartTime} onChange={(e) => setBulkStartTime(e.target.value)} />
                <label>Bulk End Time:</label>
                <input type="time" value={bulkEndTime} onChange={(e) => setBulkEndTime(e.target.value)} />
                <button onClick={() => handleBulkTimeChange(bulkStartTime, bulkEndTime, daysOfWeek)}>Apply to All Days</button>
                <button onClick={() => handleBulkTimeChange(bulkStartTime, bulkEndTime, weekdays)}>Apply to Weekdays</button>
            </div>

            <table className="table">
                <thead>
                    <tr>
                        <th>Room</th>
                        {daysOfWeek.map(day => (
                            <th key={day}>{day}</th>
                        ))}
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {/* If a site is selected, filter by site, else show all rooms */}
                    {rooms
                        .filter(room => (!selectedRoom || room.id === parseInt(selectedRoom)) && (!selectedSite || room.site.id === parseInt(selectedSite)))
                        .map(room => (
                            <tr key={room.id}>
                                <td>{room.name}</td>
                                {daysOfWeek.map(day => (
                                    <td key={day}>
                                        <input
                                            type="time"
                                            value={availability[room.id]?.[day]?.start_time || ''}
                                            onChange={(e) => handleTimeChange(room.id, day, 'start_time', e.target.value)}
                                        />
                                        <input
                                            type="time"
                                            value={availability[room.id]?.[day]?.end_time || ''}
                                            onChange={(e) => handleTimeChange(room.id, day, 'end_time', e.target.value)}
                                        />
                                    </td>
                                ))}
                                <td>
                                    <button onClick={() => handleSave(room.id)} className="btn btn-primary">Save</button>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>

            <h2>Holiday Management</h2>
            <button onClick={handleAddUsFederalHolidays} className="btn btn-secondary">
                Add US Federal Holidays (This Year and Next Year)
            </button>
            <div>
                <label>Holiday Name:</label>
                <input 
                    type="text" 
                    placeholder="Enter holiday name"
                    value={newHolidayName} 
                    onChange={(e) => setNewHolidayName(e.target.value)} 
                />
            </div>
            <div>
                <label>Add Holiday Date:</label>
                <input 
                    type="date" 
                    value={newHolidayDate}
                    onChange={(e) => setNewHolidayDate(e.target.value)} 
                />
            </div>
            <button 
                onClick={() => handleAddHoliday(newHolidayDate, newHolidayName)} 
                className="btn btn-primary"
            >
                Add Holiday
            </button>
            {/* Holiday management UI here */}
            <table className="table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Date</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {holidays.map(holiday => (
                        <tr key={holiday.id}>
                            <td>{holiday.name}</td>
                            <td>{holiday.date}</td>
                            <td>
                                <button 
                                    onClick={() => handleRemoveHoliday(holiday.id)} 
                                    className="btn btn-danger"
                                >
                                    Remove
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default RoomAvailabilityTable;