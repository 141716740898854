import React, { useState } from 'react';
import axiosInstance from '../utils/auth';
import { useNavigate } from 'react-router-dom';

const Activate = ({ email, setEmail, setActivated }) => {
  const [activationCode, setActivationCode] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    setActivationCode(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post('/api/activate/', {
        activation_code: activationCode,
        email: email  // Assuming email is a state variable in your component
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setEmail(email); // Update email state
      setActivated(true);
      navigate('/', { replace: true });
    } catch (error) {
      console.error('Activation error:', error);
      setError('Invalid activation code');
    }
  };

  return (
    <div>
      <h2>Activate Account</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Activation Code:
          <input type="text" value={activationCode} onChange={handleChange} />
        </label>
        <button type="submit">Activate</button>
        {error && <p>{error}</p>}
      </form>
    </div>
  );
};

export default Activate;