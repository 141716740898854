import React, { useState, useEffect } from 'react';
import axiosInstance from '../utils/auth';  // Adjust the import path as necessary
import { Table, Button, Form, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';  // Import useNavigate for React Router v6

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({
    username: '',
    email: '',
    is_staff: false,
  });
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate for navigation
  // Navigate back to the previous page
  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axiosInstance.get('/api/users/');
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users', error);
      }
    };
    fetchUsers();
  }, []);

  const handleChange = (e) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    setNewUser({ ...newUser, is_staff: e.target.checked });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log('new user:', newUser);
    try {
      await axiosInstance.post('/api/users/create/', newUser);
      setNewUser({ username: '', email: '', is_staff: false });
      setError('');
      const response = await axiosInstance.get('/api/users/');
      setUsers(response.data);
    } catch (error) {
      setError('Error creating user');
    }
  };

  const handleDelete = async (userId) => {
    try {
      await axiosInstance.delete(`/api/users/delete/${userId}/`);
      setUsers(users.filter((user) => user.id !== userId));
    } catch (error) {
      console.error('Error deleting user', error);
    }
  };

  return (
    <div className="container mt-5">
      <h2>User Management</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form onSubmit={handleSubmit} className="mb-4">
        <Form.Group controlId="username">
          <Form.Label>Username</Form.Label>
          <Form.Control
            type="text"
            name="username"
            placeholder="Username"
            value={newUser.username}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="email" className="mt-3">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            placeholder="Email"
            value={newUser.email}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="is_staff" className="mt-3">
          <Form.Check
            type="checkbox"
            name="is_staff"
            label="Admin"
            checked={newUser.is_staff}
            onChange={handleCheckboxChange}
          />
        </Form.Group>
        <Button variant="primary" type="submit" className="mt-3">
          Add User
        </Button>
      </Form>

      <h3>Existing Users</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Username</th>
            <th>Email</th>
            <th>Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td>{user.username}</td>
              <td>{user.email}</td>
              <td>{user.is_staff ? 'Admin' : 'Scheduling Staff'}</td> {/* Role based on is_staff */}
              <td>
                <Button
                  variant="danger"
                  onClick={() => handleDelete(user.id)}
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* Back Button */}
      <button className="btn btn-secondary mb-3" onClick={handleBack}>
        Back
      </button>
    </div>
  );
};

export default UserManagement;
