import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Link } from 'react-router-dom';
import axiosInstance, { getAccessToken, clearTokens } from './utils/auth';
import Login from './components/Login';
import Logout from './components/Logout'; // Import Logout component
import Activate from './components/Activate';
import UserRegistration from './components/UserRegistration';
import AdminUserComponent from './components/AdminUserComponent';
import UserManagement from './components/UserManagement';
import ProcedureManagement from './components/ProcedureManagement';
import Waitlist from './components/Waitlist';
import AddPatientForm from './components/AddPatientForm';
import UpdatePatientForm from './components/UpdatePatientForm';
import './App.css';
import HL7ConfigurationManager from './components/HL7ConfigurationManager';
import TestHL7Message from './components/TestHL7Message.js';
import SuccessPage from './components/SuccessPage.js';
import CommunicationAnalytics from './components/CommunicationAnalytics.js'; // Import the new component
import CommunicationDetail from './components/CommunicationDetail.js'; // Import the new component
import RoomAvailabilityTable from './components/RoomAvailability.js';
import OptOutManager from './components/OptOutManager.js'
import CommunicationReplyTable from './components/CommunicationReplyTable.js';
import HomePage from './components/HomePage.js';
import Communications from './components/Communications.js';
import AppointmentNotificationSettings from './components/NotificationSettings.js';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem('access_token') || null,
      email: '',
      activated: false, // Add activated state
      isStaff: false, //Add state to track if user is staff
      unansweredRepliesCount: 0, // Add state for unanswered count
    };
  }
  componentDidMount() {
    this.checkActivationStatus();
    this.checkStaffStatus();
    this.fetchUnansweredRepliesCount();
  
    // Set up WebSocket connection
    this.setupWebSocket();
  }

  setupWebSocket = () => {
    //const socketUrl = process.env.NODE_ENV === 'production' ? 'wss://demo.scanbooksai.com/ws/unanswered-replies/' : 'ws://localhost:8000/ws/unanswered-replies/';
    const socketUrl = 'wss://demo.scanbooksai.com/ws/unanswered-replies/';
    const socket = new WebSocket(socketUrl);

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.unansweredRepliesCount !== undefined) {
        this.setState({ unansweredRepliesCount: data.unansweredRepliesCount });
      }
    };

    socket.onclose = () => {
      console.log('WebSocket connection closed');
    };

    this.socket = socket;
  };

  componentWillUnmount() {
    if (this.socket) {
      this.socket.close();
    }
  }

  checkActivationStatus = async () => {
    const token = await getAccessToken();
    if (token) {
      axiosInstance.get('/api/check-activation-status/')
        .then(response => {
          this.setState({ activated: response.data.activated });
        })
        .catch(err => {
          console.log(err);
          this.handleLogout();
        });
    }
  };

  // Fetch if the user is a staff member
  checkStaffStatus = async () => {
    const token = await getAccessToken();
    if (token) {
      axiosInstance.get('/api/check-staff-status/')
        .then(response => {
          this.setState({ isStaff: response.data.is_staff });
        })
        .catch(err => {
          console.error('Error checking staff status:', err);
        });
    }
  };
  // Set the email state
  setEmail = (email) => {
    this.setState({ email });
  };

  // Set the token and update local storage
  setToken = (token) => {
    localStorage.setItem('access_token', token);
    this.setState({ token });
  };

  // Set the activated state
  setActivated = (activated) => {
    this.setState({ activated });
  };

  // Log out the user
  handleLogout = () => {
    clearTokens();
    this.setState({ token: null, activated: false });
  };

  render() {
    const { token, activated, isStaff, unansweredRepliesCount, email } = this.state;
    //console.log('Token:', token);
    //console.log('Activated:', activated);

    return (
      <Router>
        <div>
          <nav className="navbar navbar-expand-lg">
            <Link
                className="navbar-brand"
                to="/"
                style={{ marginLeft: '20px' }} // Add custom margin for more spacing
            >
                scanbooks
            </Link>
            {/* Add a Home link next to scanbooks */}
            <Link className="nav-link topbar-link ml-3" to="/">home</Link>
            <div className="collapse navbar-collapse d-flex justify-content-end">
              <ul className="navbar-nav">
                {token ? (
                  <>
                     {/* Conditionally render Admin link if the user is staff */}
                     {isStaff && (
                      <li className="nav-item">
                        <Link className="nav-link topbar-link" to="/admin">settings</Link>
                      </li>
                    )}
                    <li className="nav-item">
                      <Link className="nav-link topbar-link" to="/analytics">analytics</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link topbar-link" to="/unanswered-replies">
                        patient inbox
                        {/* Display the bubble if unansweredRepliesCount > 0 */}
                        {unansweredRepliesCount > 0 && (
                          <span className="reply-bubble">{unansweredRepliesCount}</span>
                        )}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link topbar-link" to="/cancels-waitlist">waitlist</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link topbar-link" to="/opt-out-manager">opt-out</Link>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link topbar-link" href="#" onClick={this.handleLogout}>logout</a>
                    </li>
                  </>
                ) : (
                  <li className="nav-item">
                    <Link className="nav-link topbar-link" to="/login">login</Link>
                  </li>
                )}
              </ul>
            </div>
          </nav>
          <main className="container-fluid">
            <div className="row">
              <div className="col-12 p-1">
                  <Routes>
                    {/* Redirect to login if no token */}
                    {!token && <Route path="*" element={<Navigate to="/login" />} />}
                    
                    {/* Route for Activate component if token is present but not activated */}
                    {token && !activated && (
                      <>
                        <Route path="/activate" element={<Activate email={this.state.email} setEmail={this.setEmail} setActivated={this.setActivated} />} />
                        <Route path="*" element={<Navigate to="/activate" />} />
                      </>
                    )}

                    {/* Routes for authenticated and activated users */}
                    {token && activated && (
                      <>
                        <Route path="/" element={<HomePage unansweredRepliesCount={unansweredRepliesCount} email={ email } />} />
                        {/* Conditionally render admin routes only if user is staff */}
                        {isStaff && (
                          <>
                            <Route path="/admin" element={<AdminUserComponent />} />
                            <Route path="/admin/user-management" element={<UserManagement />} />
                            <Route path="/admin/procedure-management" element={<ProcedureManagement />} />
                            <Route path="/admin/hl7-configuration" element={<HL7ConfigurationManager />} />
                            <Route path="/admin/test-hl7-message" element={<TestHL7Message />} />
                            <Route path="/admin/room-availability" element={<RoomAvailabilityTable />} />
                            <Route path="/admin/notification-settings" element={<AppointmentNotificationSettings />} />
                          </>
                        )}
                        <Route path="/success" element={<SuccessPage />} />
                        <Route path="/analytics" element={<CommunicationAnalytics isStaff={isStaff} />} />
                        <Route path="/waitlist/add-patient" element={<AddPatientForm />} />
                        <Route path="/waitlist/update-patient" element={<UpdatePatientForm />} />
                        <Route path="/logout" element={<Logout />} />
                        <Route path="/cancels-waitlist" element={<Waitlist />} />
                        <Route path="/communications/:communication_type/:id" element={<CommunicationDetail />} />
                        <Route path="/opt-out-manager" element={<OptOutManager />} />
                        <Route path="/unanswered-replies" element={<CommunicationReplyTable />} />
                        <Route path="/communications" element={<Communications />} />
                        <Route path="*" element={<Navigate to="/" />} />
                      </>
                    )}

                    {/* Default routes for unauthenticated users */}
                    {!token && (
                      <>
                        <Route path="/login" element={<Login setToken={this.setToken} setEmail={this.setEmail} />} />
                        <Route path="/set-password/:userId" element={<UserRegistration />} />
                        <Route path="*" element={<Navigate to="/login" />} />
                      </>
                    )}
                  </Routes>
              </div>
            </div>
          </main>
        </div>
      </Router>
    );
  }
}


  

export default App;
