import axios from 'axios';

const axiosInstance = axios.create();

export const getAccessToken = async () => {
    let token = localStorage.getItem('access_token');
    const expirationDate = localStorage.getItem('access_token_expiration');
    
    if (token && expirationDate && new Date(expirationDate) > new Date()) {
        return token;
    }

    token = await refreshAccessToken();
    return token;
};

export const getRefreshToken = () => {
    return localStorage.getItem('refresh_token');
};

export const setTokens = (accessToken, refreshToken, exp) => {
    //console.log('Current date:', new Date());
    //console.log('Expiration seconds:', exp);
    const expirationDate = new Date();
    // Assuming 'exp' is in seconds
    expirationDate.setSeconds(expirationDate.getSeconds() + exp - Math.floor(Date.now() / 1000));
    //console.log('Expiration date:', expirationDate);
    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('refresh_token', refreshToken);
    localStorage.setItem('access_token_expiration', expirationDate.toISOString());
};

export const clearTokens = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('access_token_expiration');
};

export const refreshAccessToken = async () => {
    const refreshToken = getRefreshToken();
    if (refreshToken) {
        try {
            const response = await axios.post('/api/token/refresh/', {
                refresh: refreshToken,
            });
            const { access, exp } = response.data;
            setTokens(access, refreshToken, exp);
            return access;
        } catch (error) {
            console.error('Error refreshing access token', error);
            clearTokens();
            throw error;
        }
    }
};

axiosInstance.interceptors.request.use(
    async (config) => {
        const accessToken = await getAccessToken();
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        //console.log('Request Config:', config);
        return config;
    },
    (error) => {
        //console.error('Request Error:', error);
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        //console.log('Response:', response);
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const newAccessToken = await refreshAccessToken();
            axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
            return axiosInstance(originalRequest);
        }
        //console.error('Response Error:', error);
        return Promise.reject(error);
    }
);

export default axiosInstance;

export const loginUser = async (credentials) => {
    try {
        const response = await axiosInstance.post('/api/login/', credentials);
        const { access_token, refresh_token, exp } = response.data;
        setTokens(access_token, refresh_token, exp);
        return { token: access_token, email: response.data.email }; // Returning token and email
    } catch (error) {
        console.error('Login error', error);
        throw error;
    }
};
