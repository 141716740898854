import React, { useState, useEffect } from 'react';
import axiosInstance from '../utils/auth';  // Adjust the import path as necessary
import { Form, Button, Modal, Alert } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';

const languageMap = {
  'EN': 'English',
  'ES': 'Spanish',
  'OT': 'Other'
};

const UpdatePatientForm = ({ fetchWaitlist, closeModal, show, patient }) => {
  const [formData, setFormData] = useState({
    patient_first_name: '',
    patient_last_name: '',
    patient_email: '',
    patient_mobile: '',
    procedure: null,
    current_service_date: '',
    patient_primary_language: 'EN',
  });

  const [error, setError] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `${match[2]}-${match[3]}-${match[4]}`;
    }
    return phoneNumber;
  };

  useEffect(() => {
    if (patient) {
      setFormData({
        ...patient,
        patient_mobile: formatPhoneNumber(patient.patient_mobile),
        procedure: patient.procedure.id,
        patient_primary_language: patient.patient_primary_language,
      });
    }
  }, [patient]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'patient_mobile') {
      const phoneRegex = /^\d{3}-\d{3}-\d{4}$/;
      if (!phoneRegex.test(value)) {
        setPhoneError('Phone number must be in the format 111-111-1111');
      } else {
        setPhoneError('');
      }
    }

    setFormData({
      ...formData,
      [name]: value
    });
  };

  //Fetch Procedures
  const fetchProcedures = async (inputValue) => {
    try {
        const response = await axiosInstance.get('/api/procedures/', {
            params: {search: inputValue},
        });
        return response.data.map(procedure => ({
            value: procedure.id,
            label: procedure.procedure_name
        }));
    } catch (error) {
        console.error('Error fetching procedures', error);
        return [];
    }
    };

  const handleProcedureChange = (selectedOption) => {
    setFormData(prevState => ({
        ...prevState,
        procedure: selectedOption ? selectedOption.value : null,
    }));
    };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (phoneError) {
        setError('Please correct the phone number format.');
        return;
    }

    const formattedPhone = formData.patient_mobile.replace(/-/g, '');
    const apiPhone = `+1${formattedPhone}`;
    const dataToSend = {
        ...formData,
        patient_mobile: apiPhone,
        //procedure: formData.procedure.value, // Ensure this is just the ID
        };
    //console.log('data send:', dataToSend)
    try {
      await axiosInstance.put(`/api/waitlist/${patient.id}/`, dataToSend);
      fetchWaitlist();
      closeModal();
    } catch (error) {
      setError('Error updating patient');
    }
  };

  return (
    <Modal show={show} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Update Patient</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="patient_first_name">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              name="patient_first_name"
              value={formData.patient_first_name}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="patient_last_name">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              name="patient_last_name"
              value={formData.patient_last_name}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="patient_email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="patient_email"
              value={formData.patient_email}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="patient_mobile">
          <Form.Label>Mobile</Form.Label>
          <Form.Control
            type="text"
            name="patient_mobile"
            value={formData.patient_mobile}
            onChange={handleChange}
            placeholder="111-111-1111"
          />
          {phoneError && <Alert variant="danger">{phoneError}</Alert>}
          </Form.Group>
          <Form.Group controlId="procedure">
          <Form.Label>Procedure</Form.Label>
          <AsyncSelect
            cacheOptions
            loadOptions={fetchProcedures}
            defaultOptions
            onChange={handleProcedureChange}
            isClearable
          />
          </Form.Group>
          <Form.Group controlId="current_service_date">
            <Form.Label>Current Service Date</Form.Label>
            <Form.Control
              type="date"
              name="current_service_date"
              value={formData.current_service_date}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="patient_primary_language">
            <Form.Label>Primary Language</Form.Label>
            <Form.Control
              as="select"
              name="patient_primary_language"
              value={formData.patient_primary_language}
              onChange={handleInputChange}
            >
              {Object.keys(languageMap).map((key) => (
                <option key={key} value={key}>
                  {languageMap[key]}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Button variant="primary" type="submit" disabled={phoneError !== ''}>
            Update
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default UpdatePatientForm;