import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../utils/auth';

const CommunicationDetail = () => {
  const { communication_type, id } = useParams();
  const [conversation, setConversation] = useState([]);
  const [replyMessage, setReplyMessage] = useState('');
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    fetchCommunicationDetail();
  }, [communication_type, id]);

  const fetchCommunicationDetail = async () => {
    try {
      const response = await axiosInstance.get(`/api/communications/${communication_type}/${id}/`);
      const communicationData = response.data;
      // Combine the original communication message with the replies
      const fullConversation = [
        {
          ...communicationData,
          isOriginalMessage: true, // flag for the original message
        },
        ...communicationData.replies.map(reply => ({
          ...reply,
          isOriginalMessage: false,
        })),
      ];
      // Sort the conversation by `sent_at` timestamp
      fullConversation.sort((a, b) => new Date(a.sent_at) - new Date(b.sent_at));
      

      setConversation(fullConversation);
    } catch (error) {
      console.error('Error fetching communication detail:', error);
    }
  };

  const handleReplyChange = (event) => {
    setReplyMessage(event.target.value);
  };

  const handleSendReply = async () => {
    if (!replyMessage.trim()) return;
    setIsSending(true);
    try {
      const response = await axiosInstance.post(`/api/communications/${communication_type}/${id}/reply/`, {
        message: replyMessage,
      });
      const newReply = response.data;
       // Add the new reply to the conversation state and sort again
      setConversation(prevConversation => {
        const updatedConversation = [...prevConversation, newReply];
        updatedConversation.sort((a, b) => new Date(a.sent_at) - new Date(b.sent_at));
        return updatedConversation;
      });
      setReplyMessage('');
    } catch (error) {
      console.error('Error sending reply:', error);
    } finally {
      setIsSending(false);
    }
  };

  if (conversation.length === 0) return <div>Loading...</div>;

  return (
    <div className="container-message">
      <h2>Communication Detail</h2>
      <p><strong>Recipient:</strong> {conversation[0].recipient_contact}</p>
      <p><strong>Subject:</strong> {conversation[0].subject}</p>

      <div className="conversation-container">
        {conversation.map((message, index) => {
          const isSender =
            message.communication_type === 'SMS'
              ? message.recipient_contact === message.patient_mobile
              : message.recipient_contact === message.patient_email;

          return (
            <div
              key={index}
              className={`message-bubble ${isSender ? 'sender' : 'receiver'}`}
            >
              <p>
                <strong>
                  {isSender ? 'You' : `${message.patient_name} (Patient)`}:
                </strong>
              </p>
              <p>{message.message}</p>
              <p className="message-time">{new Date(message.sent_at).toLocaleString()}</p>
            </div>
          );
        })}
      </div>

      <div className="reply-form">
        <textarea
          value={replyMessage}
          onChange={handleReplyChange}
          placeholder="Write your reply..."
          rows="3"
        />
        <button className="send-button" onClick={handleSendReply} disabled={isSending}>
          {isSending ? 'Sending...' : 'Send Reply'}
        </button>
      </div>
    </div>
  );
};

export default CommunicationDetail;
