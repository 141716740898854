// Waitlist.js
import React, { useState, useEffect } from 'react';
import axiosInstance from '../utils/auth';
import { Table, Button, Form, Alert, Modal } from 'react-bootstrap';
import AddPatientForm from './AddPatientForm';
import UpdatePatientForm from './UpdatePatientForm';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';

const Waitlist = () => {
  const [waitlist, setWaitlist] = useState([]);
  const [error, setError] = useState('');
  const [filterRooms, setFilterRooms] = useState([]);
  const [filterModality, setFilterModality] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [updateModalShow, setUpdateModalShow] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [modalities, setModalities] = useState([]);

  const languageMap = {
    'EN': 'English',
    'ES': 'Spanish',
    'OT': 'Other'
  };
  
  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[2]})-${match[3]}-${match[4]}`;
    }
    return phoneNumber;
  };

  useEffect(() => {
    fetchWaitlist();
    fetchModalities();
  }, [filterRooms, filterModality]);

  const fetchWaitlist = async () => {
    try {
        const response = await axiosInstance.get('/api/waitlist/');
      let data = response.data;
      // Apply filters
      if (filterRooms.length > 0) {
        const roomIds = filterRooms.map(room => room.value);
        data = data.filter(item => item.procedure.rooms.some(room => roomIds.includes(room.id)));
      }
      if (filterModality) {
        data = data.filter(item => item.procedure.modality.id === parseInt(filterModality));
      }
      // Calculate waiting duration
      data.forEach(item => {
        const patientAddedDate = new Date(item.patient_added_datetime);
        const now = new Date();
        const durationMs = now - patientAddedDate;

        const totalHours = Math.floor(durationMs / (1000 * 60 * 60));
        const days = Math.floor(totalHours / 24);
        const hours = totalHours % 24;
        item.waiting_time = now - patientAddedDate;
        item.waiting_duration = `${days} day(s), ${hours} hour(s)`;
        item.formatted_mobile = formatPhoneNumber(item.patient_mobile);
        item.full_language = languageMap[item.patient_primary_language];
      });


      // Sort by waiting duration
      data.sort((a, b) => b.waiting_time - a.waiting_time);

      setWaitlist(data);
    } catch (error) {
      setError('Error fetching waitlist');
    }
  };
  const fetchModalities = async () => {
    try {
      const response = await axiosInstance.get('/api/modalities/');
      setModalities(response.data);
    } catch (error) {
      console.error('Error fetching modalities', error);
    }
  };

  const fetchRooms = async (inputValue) => {
    try {
      const response = await axiosInstance.get('/api/rooms/', {
        params: { search: inputValue },
      });
      return response.data.map(room => ({
        value: room.id,
        label: room.name
      }));
    } catch (error) {
      console.error('Error fetching rooms', error);
      return [];
    }
  };


  const handleRoomFilterChange = (selectedOptions) => {
    setFilterRooms(selectedOptions || []);
  };

  const handleModalityFilterChange = (e) => {
    setFilterModality(e.target.value);
  };

  const handleDelete = async (id) => {
    try {
      await axiosInstance.delete(`/api/waitlist/${id}/`);
      fetchWaitlist();
    } catch (error) {
      setError('Error deleting patient from the waitlist');
    }
  };

  const handleUpdateClick = (patient) => {
    setSelectedPatient(patient);
    setUpdateModalShow(true);
  };

  const handleAddClick = () => {
    setShowModal(true);
  }

  //const handleShowModal = () => setShowModal(true);
  //const handleCloseModal = () => setShowModal(false);

  const handleSendEmail = async (patient) => {
    try {
      await axiosInstance.post(`/api/waitlist/${patient.id}/send-email/`);
      alert('Email sent successfully');
    } catch (error) {
      setError('Error sending email to the patient');
    }
  };
  const handleSendTopTenEmails = async () => {
    try {
      const topTenPatients = waitlist.slice(0, 10);
      await axiosInstance.post('/api/waitlist/send-top-ten-emails/', { patients: topTenPatients });
      alert('Emails sent successfully to top ten patients');
    } catch (error) {
      setError('Error sending emails to top ten patients');
    }
  };

  return (
    <div className="container mt-5">
      <h2>Cancels Waitlist</h2>
      {error && <Alert variant="danger">{error}</Alert>}

      <Button variant="primary" onClick={() => handleAddClick()}>
          Add Patient
      </Button>

      <Button variant="secondary" onClick={handleSendTopTenEmails}>
        Send Email to Top 10 Patients
      </Button>

      {showModal && (
        <AddPatientForm
          fetchWaitlist={fetchWaitlist}
          closeModal={() => setShowModal(false)}
          show={showModal}
          onHide={() => setShowModal(false)}
        />
      )}

      {selectedPatient && (
        <UpdatePatientForm
          fetchWaitlist={fetchWaitlist}
          closeModal={() => setUpdateModalShow(false)}
          show={updateModalShow}
          onHide={() => setUpdateModalShow(false)}
          patient={selectedPatient}
        />
      )}

      <Form.Group controlId="filterRoom">
        <Form.Label>Filter by Room</Form.Label>
        <AsyncSelect
          isMulti
          cacheOptions
          loadOptions={fetchRooms}
          onChange={handleRoomFilterChange}
        />
      </Form.Group>

      <Form.Group controlId="filterModality">
        <Form.Label>Filter by Modality</Form.Label>
        <Form.Control as="select" value={filterModality} onChange={handleModalityFilterChange}>
          <option value="">All Modalities</option>
          {modalities.map(modality => (
            <option key={modality.id} value={modality.id}>{modality.name}</option>
          ))}
        </Form.Control>
      </Form.Group>

      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Mobile</th>
            <th>Procedure</th>
            <th>Current Service Date</th>
            <th>Scheduled Slot Duration</th>
            <th>Modality</th>
            <th>Primary Language</th>
            <th>Waiting Duration (days, hours)</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {waitlist.map(patient => (
            <tr key={patient.id}>
              <td>{patient.patient_first_name}</td>
              <td>{patient.patient_last_name}</td>
              <td>{patient.patient_email}</td>
              <td>{patient.formatted_mobile}</td>
              <td>{patient.procedure.procedure_name}</td>
              <td>{patient.current_service_date}</td>
              <td>{patient.procedure.scheduled_slot_duration}</td>
              <td>{patient.procedure.modality.name}</td>
              <td>{patient.full_language}</td>
              <td>{patient.waiting_duration}</td>
              <td>
                <Button variant="warning" onClick={() => handleUpdateClick(patient)}>Update</Button>
                <Button variant="danger" onClick={() => handleDelete(patient.id)}>Delete</Button>
                <Button variant="primary" onClick={() => handleSendEmail(patient)}>Send Email</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default Waitlist;