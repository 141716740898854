import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/auth';

const AppointmentNotificationSettings = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState('email');
  const [templateName, setTemplateName] = useState('');
  const [bodyText, setBodyText] = useState('');
  const [reminderDays, setReminderDays] = useState([]);
  const [cancelReason, setCancelReason] = useState(''); // New: selected cancel reason
  const [notifyOnCancel, setNotifyOnCancel] = useState(true); // New: toggle for notification
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [existingTemplates, setExistingTemplates] = useState([]);
  const [cancelReasons, setCancelReasons] = useState([]); // New: List of available cancel reasons
  const [editingTemplateId, setEditingTemplateId] = useState(null);

  const placeholderMap = {
    'Patient First Name': '{patient.first_name}',
    'Patient Last Name': '{patient.last_name}',
    'Procedure Name': '{appointment.procedure_name}',
    'Appointment Date & Time': '{appointment.scheduled_datetime}',
    'Site Name': '{site.name}',
    'Site Address': '{site.address}',
    'Site City': '{site.city}',
    'Site State': '{site.state}',
    'Site Zipcode': '{site.zipcode}'
  };

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await axiosInstance.get('/api/get-notification-templates/');
        setExistingTemplates(response.data);
      } catch (error) {
        console.error('Error fetching templates:', error);
      }
    };
    const fetchCancelReasons = async () => {
      try {
        const response = await axiosInstance.get('/api/cancel-reasons/');
        setCancelReasons(response.data);
      } catch (error) {
        console.error('Error fetching cancel reasons:', error);
      }
    };

    fetchTemplates();
    fetchCancelReasons(); // Fetch cancel reasons on load
  }, []);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    setBodyText('');
    setTemplateName('');
    setReminderDays([]);
  };

  const handleTemplateNameChange = (e) => {
    setTemplateName(e.target.value);
  };

  const handleBodyTextChange = (e) => {
    setBodyText(e.target.value);
  };

  const handleReminderChange = (day) => {
    const updatedReminderDays = reminderDays.includes(day)
      ? reminderDays.filter((d) => d !== day)
      : [...reminderDays, day];

    if (updatedReminderDays.length <= 3) {
      setReminderDays(updatedReminderDays);
    }
  };

  const handleCancelReasonChange = (e) => {
    setCancelReason(e.target.value);
  };

  const handleNotifyToggle = () => {
    setNotifyOnCancel(!notifyOnCancel);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!templateName || !bodyText || (templateName.includes('Reminder') && reminderDays.length === 0)) {
      setErrorMessage('Please fill all required fields.');
      return;
    }

    try {
      const data = {
        template_type: selectedOption,
        template_name: templateName,
        template_body: bodyText,
        reminder_days: reminderDays,
        cancel_reason: cancelReason, // Include cancel reason in data
        notify_on_cancel: notifyOnCancel, // Include toggle for cancel notification
      };

      if (editingTemplateId) {
        await axiosInstance.put(`/api/update-notification-body/${editingTemplateId}/`, data);
        setSuccessMessage('Template updated successfully!');
      } else {
        await axiosInstance.post('/api/update-notification-body/', data);
        setSuccessMessage('Template created successfully!');
      }

      setErrorMessage('');
      setEditingTemplateId(null);
      setTemplateName('');
      setBodyText('');
      setReminderDays([]);
      const response = await axiosInstance.get('/api/get-notification-templates/');
      setExistingTemplates(response.data);

    } catch (error) {
      setErrorMessage('Error saving template. Please try again.');
      setSuccessMessage('');
    }
  };

  const handleEdit = (template) => {
    setTemplateName(template.template_name);
    setBodyText(template.template_body);
    setReminderDays(template.reminder_days);
    setSelectedOption(template.template_type);
    setCancelReason(template.cancel_reason);
    setNotifyOnCancel(template.notify_on_cancel);
    setEditingTemplateId(template.id);
  };

  const handleDelete = async (id) => {
    try {
      await axiosInstance.delete(`/api/delete-notification-template/${id}/`);
      setExistingTemplates(existingTemplates.filter((template) => template.id !== id));
      setSuccessMessage('Template deleted successfully!');
    } catch (error) {
      setErrorMessage('Error deleting template.');
    }
  };

  const handleDragStart = (e, placeholder) => {
    e.dataTransfer.setData('text/plain', placeholder);
  };

  // Handle Drop with insertion at the correct spot
  const handleDrop = (e) => {
    e.preventDefault();
    const placeholder = e.dataTransfer.getData('text/plain');
    
    // Get the target textarea and mouse position relative to the textarea
    const textarea = e.target;
    const dropPosition = textarea.selectionStart;
  
    // Get the text from the textarea before and after the cursor position
    const textBeforeDrop = textarea.value.substring(0, dropPosition);
    const textAfterDrop = textarea.value.substring(dropPosition);
  
    // Insert the placeholder at the drop position
    const newBodyText = `${textBeforeDrop}${placeholder}${textAfterDrop}`;
    
    // Update the textarea with the new body text
    setBodyText(newBodyText);
  };
  
  

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleBack = () => {
    navigate(-1);
  };

  const templateOptions = selectedOption === 'sms'
    ? ['Appointment Confirmation SMS', 'Appointment Reminder SMS', 'Appointment Cancel SMS']
    : ['Appointment Confirmation Email', 'Appointment Reminder Email', 'Appointment Cancel Email'];

  return (
    <div className="container">
      <h2>Appointment Notification Settings</h2>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-8">
            <div className="form-group">
              <label htmlFor="notification-type">Select Notification Type:</label>
              <select
                id="notification-type"
                className="form-control"
                value={selectedOption}
                onChange={handleOptionChange}
              >
                <option value="email">Email</option>
                <option value="sms">SMS</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="template-name">Communication Template Name:</label>
              <select
                id="template-name"
                className="form-control"
                value={templateName}
                onChange={handleTemplateNameChange}
              >
                <option value="">Select Template</option>
                {templateOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="notification-body">
                {selectedOption === 'email' ? 'Email Body' : 'SMS Body'}:
              </label>
              <textarea
                id="notification-body"
                className="form-control"
                rows="5"
                value={bodyText}
                onChange={handleBodyTextChange}
                placeholder={`Enter ${selectedOption === 'email' ? 'Email' : 'SMS'} Body with placeholders`}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
              ></textarea>
            </div>

            {/* Cancel reason dropdown */}
            {templateName.includes('Cancel') && (
              <div className="form-group">
                <label htmlFor="cancel-reason">Select Cancel Reason:</label>
                <select
                  id="cancel-reason"
                  className="form-control"
                  value={cancelReason}
                  onChange={handleCancelReasonChange}
                >
                 <option value="">Select Cancel Reason</option>
                  {cancelReasons.map((reason) => (
                    <option key={reason.id} value={reason.reason}>
                      {reason.reason}
                  </option>
                  ))}
                </select>

                {/* Notification toggle */}
                <div className="form-group form-check mt-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="notify-on-cancel"
                    checked={notifyOnCancel}
                    onChange={handleNotifyToggle}
                  />
                  <label className="form-check-label" htmlFor="notify-on-cancel">
                    Send Notification for this Cancel Reason
                  </label>
                </div>
              </div>
            )}

            {templateName.includes('Reminder') && (
              <div className="form-group">
                <label>Select Reminder Days (Max 3):</label>
                <div className="d-flex flex-wrap">
                  {Array.from({ length: 10 }, (_, i) => i + 1).map((day) => (
                    <div key={day} className="form-check mr-2">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={`reminder-day-${day}`}
                        checked={reminderDays.includes(day)}
                        onChange={() => handleReminderChange(day)}
                      />
                      <label className="form-check-label" htmlFor={`reminder-day-${day}`}>
                        {day}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          <div className="col-md-4">
            <div className="form-group">
              <label>Drag and drop placeholders:</label>
              <ul className="tag-list">
                {Object.keys(placeholderMap).map((friendlyName, index) => (
                  <li
                    key={index}
                    draggable
                    onDragStart={(e) => handleDragStart(e, placeholderMap[friendlyName])}
                    className="tag-item"
                  >
                    {friendlyName}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <button type="submit" className="btn btn-primary mt-3">
          {editingTemplateId ? 'Update Template' : 'Create Template'}
        </button>

        {successMessage && <p className="text-success mt-2">{successMessage}</p>}
        {errorMessage && <p className="text-danger mt-2">{errorMessage}</p>}
      </form>

      <h4 className="mt-5">Existing Templates:</h4>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Cancel Reason</th>
            <th>Reminder Days</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {existingTemplates.map((template) => (
            <tr key={template.id}>
              <td>{template.template_name}</td>
              <td>{template.template_type}</td>
              <td>{template.cancel_reason}</td>
              <td>{template.reminder_days ? template.reminder_days.join(', ') : ''}</td>
              <td>
                <button className="btn btn-warning" onClick={() => handleEdit(template)}>
                  Edit
                </button>
                <button className="btn btn-danger" onClick={() => handleDelete(template.id)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <button onClick={handleBack} className="btn btn-secondary mt-3">
        Back
      </button>
    </div>
  );
};

export default AppointmentNotificationSettings;