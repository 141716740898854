import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/auth';
import AsyncSelect from 'react-select/async';
import DatePicker from 'react-datepicker';
import { Form, Collapse, Button } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';

const Communications = () => {
    const [communications, setCommunications] = useState([]);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [sites, setSites] = useState([]);
    const [selectedSites, setSelectedSites] = useState([]);
    const [selectedRoom, setSelectedRoom] = useState([]);
    const [selectedModality, setSelectedModalities] = useState([]);
    const [specificStartDate, setSpecificStartDate] = useState(null);
    const [specificEndDate, setSpecificEndDate] = useState(null);
    const [relativePeriodNumber, setRelativePeriodNumber] = useState(''); // Default to 3
    const [relativePeriodUnit, setRelativePeriodUnit] = useState(''); // Default to week
    const [modalities, setModalities] = useState([]);
    const navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
  
    useEffect(() => {
        fetchCommunications();
        fetchModalities();
        fetchSites();
      }, [selectedPatient, selectedSites, selectedRoom, selectedModality, specificStartDate, specificEndDate, relativePeriodNumber, relativePeriodUnit]);
    
      
    // Sorting function
    const sortedCommunications = [...communications].sort((a, b) => {
        if (sortConfig.key) {
            let aValue = a[sortConfig.key];
            let bValue = b[sortConfig.key];

            if (sortConfig.key === 'sent_at') {
                aValue = new Date(aValue);
                bValue = new Date(bValue);
            } else {
                aValue = aValue ? aValue.toString().toLowerCase() : '';
                bValue = bValue ? bValue.toString().toLowerCase() : '';
            }

            if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
    });

    // Handle column header click for sorting
    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    // Helper to display the correct arrow based on sort direction
    const getSortArrow = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'asc' ? '▲' : '▼';
        }
        return '↕'; // Default arrow
    };
  
      // Fetch communications based on filters
    const fetchCommunications = async () => {
        try {
        const response = await axiosInstance.get('/api/communications/', {
            params: {
            patient: selectedPatient?.value,
            site: selectedSites.length > 0 ? selectedSites.join(',') : null,
            room: selectedRoom?.map(room => room.value).join(','),
            modality: selectedModality.length > 0 ? selectedModality.join(',') : null,
            start_date: specificStartDate ? specificStartDate.toISOString().split('T')[0] : formatDateFilter(),
            end_date: specificEndDate ? specificEndDate.toISOString().split('T')[0] : null,
            }
        });
        // Assuming the response structure is { combined_data: [], unresponded_patient_replies_count: 0 }
        const { combined_data, unresponded_patient_replies_count } = response.data;

        // Set the communications data (combined_data) in your state
        setCommunications(combined_data);
        } catch (error) {
        console.error('Error fetching communications:', error);
        }
    };
    const formatDateFilter = () => {
        const today = new Date();
        let startDate;
        switch (relativePeriodUnit) {
          case 'day':
            startDate = new Date(today.setDate(today.getDate() - relativePeriodNumber));
            break;
          case 'week':
            startDate = new Date(today.setDate(today.getDate() - (relativePeriodNumber * 7)));
            break;
          case 'month':
            startDate = new Date(today.setMonth(today.getMonth() - relativePeriodNumber));
            break;
          case 'quarter':
            startDate = new Date(today.setMonth(today.getMonth() - (relativePeriodNumber * 3)));
            break;
          case 'year':
            startDate = new Date(today.setFullYear(today.getFullYear() - relativePeriodNumber));
            break;
          default:
            startDate = null;
        }
        return startDate ? startDate.toISOString().split('T')[0] : null;
      };
    
      const handleRelativePeriodNumberChange = (event) => {
        setRelativePeriodNumber(event.target.value);
      };
    
      const handleRelativePeriodUnitChange = (event) => {
        setRelativePeriodUnit(event.target.value);
      };
    
      const handleSpecificStartDateChange = (date) => {
        setSpecificStartDate(date);
      };
    
      const handleSpecificEndDateChange = (date) => {
        setSpecificEndDate(date);
      };
    
      const fetchPatientSuggestions = async (inputValue) => {
        try {
          const response = await axiosInstance.get('/api/patient-suggestions/', { params: { query: inputValue } });
          return response.data.map(patient => ({
            label: `${patient.first_name} ${patient.last_name}`,
            value: patient.id
          }));
        } catch (error) {
          console.error('Error fetching patient suggestions:', error);
          return [];
        }
      };
      const fetchSites = async () => {
          try {
              const response = await axiosInstance.get('/api/sites/');
              setSites(response.data);
          } catch (error) {
              console.error('Error fetching sites:', error);
          }
      };
    
    
      const fetchModalities = async () => {
          try {
            const response = await axiosInstance.get('/api/modalities/');
            setModalities(response.data);
          } catch (error) {
            console.error('Error fetching modalities', error);
          }
      };
    
      const fetchRooms = async (inputValue) => {
          try {
            const response = await axiosInstance.get('/api/rooms/', {
              params: { search: inputValue },
            });
            return response.data.map(room => ({
              value: room.id,
              label: room.name
            }));
          } catch (error) {
            console.error('Error fetching rooms', error);
            return [];
          }
      };
    
    
      const handlePatientChange = (selectedPatient) => {
        setSelectedPatient(selectedPatient);
      };
    
      const handleSiteFilterChange = (event) => {
        const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
        setSelectedSites(selectedOptions);
      };
    
      const handleRoomChange = (selectedRooms) => {
        setSelectedRoom(selectedRooms);
      };
    
      const handleModalityFilterChange = (event) => {
        const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
        setSelectedModalities(selectedOptions);
      };
      
      const totalCommunications = communications.length;

      const toggleSidebar = () => {
        setCollapsed(!collapsed); // Toggle sidebar collapse state
      };
    
      // Clear all filters
      const clearFilters = () => {
        setSelectedSites([]);
        setSelectedRoom([]);
        setSelectedModalities([]);
        setRelativePeriodNumber('');
        setRelativePeriodUnit('');
        setSpecificStartDate(null);
        setSpecificEndDate(null);
      };

  return (
    <div className="container-fluid">
      <div className="d-flex">
        {/* Sidebar Toolbar */}
        <div
          className={`sidebar-toolbar p-3 border-end bg-light ${collapsed ? 'collapsed' : ''}`}
          style={{
            width: collapsed ? '60px' : '300px',
            transition: 'width 0.3s',
            display: 'flex',
            flexDirection: 'column',
            alignItems: collapsed ? 'center' : 'flex-start'
          }}
        >
          {/* Filters label that is always visible when collapsed */}
          {collapsed && (
            <div
              style={{
                color: '#0f1955', // Bootstrap primary blue or custom color for visibility
                marginBottom: '10px',
                textAlign: 'center',
                fontSize: '14px',
                fontWeight: 'bold',
              }}
            >
              Filters
            </div>
          )}
          {/* Toggle button */}
          <Button
            variant="primary"
            onClick={toggleSidebar}
            className="mb-3"
            style={{
              width: collapsed ? '100%' : '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: collapsed ? 'column' : 'row'
            }}
          >
            {collapsed ? (
              <>
                <span>&gt;&gt;</span>
              </>
            ) : (
              <>
                <span>&lt;&lt;</span>
              </>
            )}
          </Button>

          {/* Collapse content */}
          <Collapse in={!collapsed}>
            <div>
              <h4 className="text-center">Filters</h4>

              <Form.Group className="mb-3">
                <Form.Label>Sites</Form.Label>
                <Form.Control
                  as="select"
                  multiple
                  value={selectedSites}
                  onChange={handleSiteFilterChange}
                >
                  {sites.map((site) => (
                    <option key={site.id} value={site.id}>
                      {site.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Rooms</Form.Label>
                <AsyncSelect
                  isMulti
                  cacheOptions
                  loadOptions={fetchRooms}
                  onChange={handleRoomChange}
                  value={selectedRoom}
                  isClearable
                  placeholder="Select rooms"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Modalities</Form.Label>
                <Form.Control
                  as="select"
                  multiple
                  value={selectedModality}
                  onChange={handleModalityFilterChange}
                >
                  {modalities.map((modality) => (
                    <option key={modality.id} value={modality.id}>
                      {modality.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Last</Form.Label>
                <Form.Control
                  type="number"
                  value={relativePeriodNumber}
                  onChange={handleRelativePeriodNumberChange}
                  placeholder="Number of periods"
                />
                <Form.Control
                  as="select"
                  value={relativePeriodUnit}
                  onChange={handleRelativePeriodUnitChange}
                  className="mt-2"
                >
                  <option value="day">Days</option>
                  <option value="week">Weeks</option>
                  <option value="month">Months</option>
                  <option value="quarter">Quarters</option>
                  <option value="year">Years</option>
                </Form.Control>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Specific Scheduled Date Range</Form.Label>
                <DatePicker
                  selected={specificStartDate}
                  onChange={handleSpecificStartDateChange}
                  dateFormat="yyyy/MM/dd"
                  placeholderText="Start date"
                  className="form-control mb-2"
                />
                <DatePicker
                  selected={specificEndDate}
                  onChange={handleSpecificEndDateChange}
                  dateFormat="yyyy/MM/dd"
                  placeholderText="End date"
                  className="form-control"
                />
              </Form.Group>
              {/* Clear Filters Button */}
              <Button
                variant="secondary"
                onClick={clearFilters}
                className="mt-3"
                style={{ width: '100%' }}
              >
                Reset Filters
              </Button>
            </div>
          </Collapse>
        </div>
        

       <div>
            <h5>Patient Communications Log ({totalCommunications})</h5>
            <Form.Group className="mb-3">
            <Form.Label>Search Patient:</Form.Label>
            <AsyncSelect
              cacheOptions
              loadOptions={fetchPatientSuggestions}
              onChange={handlePatientChange}
              value={selectedPatient}
              isClearable
              placeholder="Select patient"
            />
          </Form.Group>
            {/* Table of all communications */}
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th onClick={() => handleSort('patient_name')}>
                            Patient Name {getSortArrow('patient_name')}
                        </th>
                        <th onClick={() => handleSort('communication_type')}>
                            Message Type {getSortArrow('communication_type')}
                        </th>
                        <th onClick={() => handleSort('subject')}>
                            Campaign {getSortArrow('subject')}
                        </th>
                        <th onClick={() => handleSort('sent_at')}>
                            Sent At {getSortArrow('sent_at')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {sortedCommunications.map((communication) => (
                        <tr
                            key={communication.id}
                            onClick={() =>
                                navigate(
                                    `/communications/${communication.communication_type}/${communication.id}/`
                                )
                            }
                            style={{ cursor: 'pointer' }}
                        >
                            <td>{communication.patient_name}</td>
                            <td>{communication.communication_type || 'Email'}</td>
                            <td>{communication.subject || communication.message}</td>
                            <td>{new Date(communication.sent_at).toLocaleString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </div>
</div>
  );
};

export default Communications;
