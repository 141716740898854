import React from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const AdminUserComponent = () => {
  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={6}>
          <Card className="text-center mb-4">
            <Card.Body>
              <Card.Title>User Management</Card.Title>
              <Card.Text>
                Manage users, add new users, and delete existing users.
              </Card.Text>
              <Link to="/admin/user-management" className="btn btn-primary">Manage Users</Link>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="text-center mb-4">
            <Card.Body>
              <Card.Title>Procedure Management</Card.Title>
              <Card.Text>
                Upload and manage procedures, tag rooms to procedures.
              </Card.Text>
              <Link to="/admin/procedure-management" className="btn btn-primary">Go to Procedures</Link>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="text-center mb-4">
            <Card.Body>
              <Card.Title>HL7 Integration Management</Card.Title>
              <Card.Text>
                Manage HL7 integration changes and field mappings.
              </Card.Text>
              <Link to="/admin/hl7-configuration" className="btn btn-primary">Integrate</Link>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="text-center mb-4">
            <Card.Body>
              <Card.Title>Room Availability</Card.Title>
              <Card.Text>
                Manage your Radiology rooms and their availability.
              </Card.Text>
              <Link to="/admin/room-availability" className="btn btn-primary">Set Availability</Link>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="text-center mb-4">
            <Card.Body>
              <Card.Title>Notification Settings</Card.Title>
              <Card.Text>
                Build custom, personal communication templates for Appointment Confirmations, Reminders and Cancel notifications.
              </Card.Text>
              <Link to="/admin/notification-settings" className="btn btn-primary">Set Communication Templates</Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminUserComponent;