import React, { useState, useEffect } from 'react';
import axiosInstance from '../utils/auth';
import { Table, Form, Button, InputGroup } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';  // Import useNavigate for React Router v6

const OptOutManager = () => {
  const [patients, setPatients] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate for navigation
  // Navigate back to the previous page
  const handleBack = () => {
    navigate(-1);
  };

  // Fetch opt-out patients from the backend
  useEffect(() => {
    const fetchOptOutPatients = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get('/api/patients/opt_out/');
        setPatients(response.data);
      } catch (error) {
        console.error('Error fetching opt-out patients:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOptOutPatients();
  }, []);

  // Handle opt-out status update
  const handleOptOutChange = async (patientId, currentStatus) => {
    try {
      await axiosInstance.patch(`/api/patients/${patientId}/update_opt_out/`, {
        opt_out: !currentStatus,
      });
      setPatients(prevPatients =>
        prevPatients.map(patient =>
          patient.id === patientId ? { ...patient, opt_out: !currentStatus } : patient
        )
      );
    } catch (error) {
      console.error('Error updating opt-out status:', error);
    }
  };

  // Load patients for searching by name, email, or mobile
  const loadPatientOptions = async (inputValue) => {
    try {
      const response = await axiosInstance.get(`/api/patients/search/`, {
        params: { query: inputValue },
      });
      return response.data.map(patient => ({
        value: patient.id,
        label: `${patient.first_name} ${patient.last_name} (${patient.patient_mobile || patient.patient_email})`,
      }));
    } catch (error) {
      console.error('Error fetching patients for search:', error);
      return [];
    }
  };

  // Handle patient selection from search
  const handlePatientSelect = (selectedOption) => {
    setSelectedPatient(selectedOption);
  };

  // Manually opt out the selected patient
  const handleManualOptOut = async () => {
    if (selectedPatient) {
      try {
        await axiosInstance.patch(`/api/patients/${selectedPatient.value}/update_opt_out/`, { 
          opt_out: true, 
          reason: 'manual' 
        });
        setPatients([...patients, { 
          id: selectedPatient.value, 
          name: selectedPatient.label, 
          opt_out: true, 
          updated_at: new Date(), 
          opt_out_reason: 'manual' 
        }]);
        setSelectedPatient(null); // Clear the selected patient after opt-out
      } catch (error) {
        console.error('Error manually opting out patient:', error);
      }
    }
  };

  return (
    <div>
      <h3>Manage Opt-Out Patients</h3>

      {/* Search for patients to manually opt-out */}
      <InputGroup className="mb-3">
        <AsyncSelect
          cacheOptions
          loadOptions={loadPatientOptions}
          onChange={handlePatientSelect}
          value={selectedPatient}
          placeholder="Search patient by name"
          isClearable
        />
        <Button onClick={handleManualOptOut} disabled={!selectedPatient}>
          Add to Opt-Out
        </Button>
      </InputGroup>

      {/* Table displaying opt-out patients */}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Patient Name</th>
            <th>MRN</th>
            <th>Opt-Out Status</th>
            <th>Last Updated</th>
            <th>Reason (Manual/Text)</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {patients.map(patient => (
            <tr key={patient.id}>
              <td>{patient.first_name} {patient.last_name}</td>
              <td>{patient.patient_id}</td>
              <td>{patient.opt_out ? 'Opted Out' : 'Subscribed'}</td>
              <td>{moment(patient.updated_at).format('YYYY-MM-DD HH:mm')}</td>
              <td>{patient.opt_out_reason}</td>
              <td>
                <Button
                  variant={patient.opt_out ? 'success' : 'danger'}
                  onClick={() => handleOptOutChange(patient.id, patient.opt_out)}
                >
                  {patient.opt_out ? 'Re-subscribe' : 'Opt-Out'}
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {loading && <p>Loading patients...</p>}
      {/* Back Button */}
      <button className="btn btn-secondary mb-3" onClick={handleBack}>
        Back
      </button>
    </div>
  );
};

export default OptOutManager;
