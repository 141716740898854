import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { clearTokens } from '../utils/auth'; // Function to clear tokens from local storage or cookies

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Clear tokens from local storage or cookies
    clearTokens();

    // Optionally, you can make an API call to the server to invalidate tokens
    // await axios.post('/api/logout/');

    // Redirect to login page or a public page
    navigate('/login');
  }, [navigate]);

  return (
    <div className="logout-container">
      <p>Logging out...</p>
    </div>
  );
};

export default Logout;
