import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '../utils/auth';
import { Line } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import DatePicker from 'react-datepicker';
import { Form, Collapse, Button } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';

// Registering ChartJS components
import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import 'chartjs-adapter-luxon';

ChartJS.register(TimeScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const CommunicationAnalytics = (isStaff) => {
  const [communications, setCommunications] = useState([]);
  const [trendData, setTrendData] = useState({ labels: [], datasets: [] });
  const [schedulingData, setSchedulingData] = useState({ labels: [], datasets: [] });
  const [totalEngagement, setOverallSchedulingMetric] = useState(0);
  const [utilizationData, setUtilizationData] = useState({ labels: [], datasets: [] });
  const [totalUtilization, setTotalUtilization] = useState(0); // For overall utilization callout
  const [granularity, setGranularity] = useState('day');
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [sites, setSites] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState([]);
  const [selectedModality, setSelectedModalities] = useState([]);
  const [specificStartDate, setSpecificStartDate] = useState(null);
  const [specificEndDate, setSpecificEndDate] = useState(null);
  const [relativePeriodNumber, setRelativePeriodNumber] = useState(''); // Default to 3
  const [relativePeriodUnit, setRelativePeriodUnit] = useState(''); // Default to week
  const [modalities, setModalities] = useState([]);
  const [showBySite, setShowBySite] = useState(false); // Toggle for site-based lines
  const [showByRoom, setShowByRoom] = useState(false); // Toggle for room-based lines
  const navigate = useNavigate();
  const chartEndRef = useRef(null);
  const [collapsed, setCollapsed] = useState(false);
  const [targetUtilization, setTargetUtilization] = useState(''); // Initialize with an empty string
  const [targetExists, setTargetExists] = useState(false);
  // Navigate back to the previous page
  const handleBack = () => {
    navigate(-1);
  };
  

  useEffect(() => {
    fetchCommunications();
    fetchModalities();
    fetchSites();
    fetchTrendData(granularity, showBySite, showByRoom);
    fetchSchedulingData(granularity, showBySite, showByRoom);
    fetchUtilizationData(granularity, showBySite, showByRoom);
    // Fetch the target utilization from the API
    const fetchTargetUtilization = async () => {
      try {
        const response = await axiosInstance.get('/api/target-utilization/');
        if (response.data) {
          setTargetUtilization(response.data.target);
          setTargetExists(true);
          const data = response.data;
          setUtilizationData(prevData => ({
            ...prevData,
            datasets: [
              ...prevData.datasets.filter(dataset => !dataset.label.includes('Target Utilization')),
              {
                label: `Target Utilization (${data.target}%)`,
                data: new Array(prevData.labels.length).fill(data.target),
                fill: false,
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderDash: [10, 5],
              }
            ]
          }));
        }
      } catch (error) {
        console.error('Failed to fetch target utilization:', error);
      }
    };
  
    fetchTargetUtilization();
  }, [granularity, showBySite, showByRoom, selectedPatient, selectedSites, selectedRoom, selectedModality, specificStartDate, specificEndDate, relativePeriodNumber, relativePeriodUnit]);

  // handle target submit
  const handleTargetSubmit = async () => {
    try {
      const method = targetExists ? 'put' : 'post'; // Determine whether to use PUT or POST
      const url = '/api/update-target-utilization/';

      let response;
      if (method === 'put') {
        response = await axiosInstance.put(url, {
          target: targetUtilization,
        });
      } else {
        response = await axiosInstance.post(url, {
          target: targetUtilization,
        });
      }
      // Update the utilization data directly after successful submission
      const data = response.data; // Assuming response contains the updated target data

      setUtilizationData(prevData => ({
        ...prevData,
        datasets: [
          ...prevData.datasets.filter(dataset => !dataset.label.includes('Target Utilization')),
          {
            label: `Target Utilization (${data.target}%)`,
            data: new Array(prevData.labels.length).fill(data.target),
            fill: false,
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderDash: [10, 5],
          }
        ]
      }));

      // Optionally handle success
      console.log('Target utilization updated:', response.data);
    } catch (error) {
      console.error('Failed to update target utilization:', error);
    }
  };
  
  // Fetch communications based on filters
  const fetchCommunications = async () => {
    try {
      const response = await axiosInstance.get('/api/communications/', {
        params: {
          patient: selectedPatient?.value,
          site: selectedSites.length > 0 ? selectedSites.join(',') : null,
          room: selectedRoom?.map(room => room.value).join(','),
          modality: selectedModality.length > 0 ? selectedModality.join(',') : null,
          start_date: specificStartDate ? specificStartDate.toISOString().split('T')[0] : formatDateFilter(),
          end_date: specificEndDate ? specificEndDate.toISOString().split('T')[0] : null,
        }
      });
      // Assuming the response structure is { combined_data: [], unresponded_patient_replies_count: 0 }
      const { combined_data, unresponded_patient_replies_count } = response.data;

      // Set the communications data (combined_data) in your state
      setCommunications(combined_data);
    } catch (error) {
      console.error('Error fetching communications:', error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (granularity === 'month') {
      return date.toISOString().substring(0, 7); // "YYYY-MM"
    } else if (granularity === 'week') {
      const firstDayOfWeek = new Date(date.setDate(date.getDate() - date.getDay())); // Get first day of week
      return firstDayOfWeek.toISOString().substring(0, 10); // "YYYY-MM-DD"
    } else { // Default is day granularity
      return date.toISOString().substring(0, 10); // "YYYY-MM-DD"
    }
  };

  // Fetch trend data for communications
  const fetchTrendData = async (granularity, showBySite, showByRoom) => {
    try {
      const response = await axiosInstance.get('/api/communications/trend/', {
        params: {
          granularity,
          patient: selectedPatient?.value,
          site: selectedSites.length > 0 ? selectedSites.join(',') : null,
          room: selectedRoom?.map(room => room.value).join(','),
          modality: selectedModality.length > 0 ? selectedModality.join(',') : null,
          start_date: specificStartDate ? specificStartDate.toISOString().split('T')[0] : formatDateFilter(),
          end_date: specificEndDate ? specificEndDate.toISOString().split('T')[0] : null,
          by_site: showBySite,
          by_room: showByRoom,
        }
      });
      //setTrendData({
       // labels: response.data.map(item => new Date(item.date)),
        //datasets: [
         // {
          //  label: 'Communications Sent',
          //  data: response.data.map(item => item.count),
          //  fill: false,
         //   backgroundColor: '#0e1955',
         //   borderColor: '#0e1955',
         // }
       // ]
      //});
      const { data } = response;

      // Extract unique labels (dates based on granularity)
      const labels = Array.from(new Set(data.map(item => formatDate(item.date))));

      let datasets = [];

      if (showBySite) {
        // Group data by site and map utilization percentages by date
        const siteGroups = data.reduce((acc, item) => {
          const siteName = item.site_name;
          if (!acc[siteName]) {
            acc[siteName] = {
              label: `Communications for Site: ${siteName}`,
              data: new Array(labels.length).fill(null), // Align by dates
              borderColor: generateColor('#0e1955', Object.keys(acc).length),
              backgroundColor: generateColor('#0e1955', Object.keys(acc).length),
              fill: false,
            };
          }
          const dateIndex = labels.indexOf(formatDate(item.date));
          if (dateIndex >= 0) {
            acc[siteName].data[dateIndex] = item.total_communications;
          }
          return acc;
        }, {});

        datasets.push(...Object.values(siteGroups));
      } else if (showByRoom) {
        // Group data by room and map utilization percentages by date
        const roomGroups = data.reduce((acc, item) => {
          const roomName = item.room_name;
          if (!acc[roomName]) {
            acc[roomName] = {
              label: `Communications for Room: ${roomName}`,
              data: new Array(labels.length).fill(null), // Align by dates
              borderColor: generateColor('#0e1955', Object.keys(acc).length),
              backgroundColor: generateColor('#0e1955', Object.keys(acc).length),
              fill: false,
            };
          }
          const dateIndex = labels.indexOf(formatDate(item.date));
          if (dateIndex >= 0) {
            acc[roomName].data[dateIndex] = item.total_communications;
          }
          return acc;
        }, {});

        datasets.push(...Object.values(roomGroups));
      } else {
        // Default dataset if no room/site is specified
        datasets.push({
          label: 'Total Communications',
          data: labels.map(date => {
            const item = data.find(d => formatDate(d.date) === date);
            return item ? item.total_communications : null;
          }),
          fill: false,
          backgroundColor: '#0e1955',
          borderColor: '#0e1955',
        });
      }

      setTrendData({
        labels,
        datasets,
      });

    } catch (error) {
      console.error('Error fetching trend data:', error);
    }
  };

  const fetchSchedulingData = async (granularity, showBySite, showByRoom) => {
    try {
      const response = await axiosInstance.get('/api/communications/scheduling-trend/', {
        params: {
          granularity,
          patient: selectedPatient?.value,
          site: selectedSites.length > 0 ? selectedSites.join(',') : null,
          room: selectedRoom?.map(room => room.value).join(','),
          modality: selectedModality.length > 0 ? selectedModality.join(',') : null,
          start_date: specificStartDate ? specificStartDate.toISOString().split('T')[0] : formatDateFilter(),
          end_date: specificEndDate ? specificEndDate.toISOString().split('T')[0] : null,
          by_site: showBySite,
          by_room: showByRoom,
        }
      });
  
      //const trendPercentageData = response.data.map(item => {
       // const { total_appointments, appointments_with_communications } = item;
       // return {
         // date: new Date(item.date),
         // percentage: total_appointments > 0
         //   ? (appointments_with_communications / total_appointments) * 100
         ///   : 0
      //  };
     // });
  
      // Calculate overall percentage
      const totalAppointments = response.data.reduce((sum, item) => sum + item.total_appointments, 0);
      const totalAppointmentsWithCommunications = response.data.reduce((sum, item) => sum + item.appointments_with_communications, 0);
      const overallPercentage = totalAppointments > 0
        ? (totalAppointmentsWithCommunications / totalAppointments) * 100
        : 0;
  
      setOverallSchedulingMetric(overallPercentage.toFixed(2));  // Set overall percentage

      const { data } = response;

      // Extract unique labels (dates based on granularity)
      const labels = Array.from(new Set(data.map(item => formatDate(item.date))));

      let datasets = [];

      if (showBySite) {
        // Group data by site and map utilization percentages by date
        const siteGroups = data.reduce((acc, item) => {
          const siteName = item.site_name;
          if (!acc[siteName]) {
            acc[siteName] = {
              label: `Schedules Engaged (%) for Site: ${siteName}`,
              data: new Array(labels.length).fill(null), // Align by dates
              borderColor: generateColor('#0e1955', Object.keys(acc).length),
              backgroundColor: generateColor('#0e1955', Object.keys(acc).length),
              fill: false,
            };
          }
          const dateIndex = labels.indexOf(formatDate(item.date));
          if (dateIndex >= 0) {
            acc[siteName].data[dateIndex] = item.engaged_percentage * 100;
          }
          return acc;
        }, {});

        datasets.push(...Object.values(siteGroups));
      } else if (showByRoom) {
        // Group data by room and map utilization percentages by date
        const roomGroups = data.reduce((acc, item) => {
          const roomName = item.room_name;
          if (!acc[roomName]) {
            acc[roomName] = {
              label: `Schedules Engaged (%) for Room: ${roomName}`,
              data: new Array(labels.length).fill(null), // Align by dates
              borderColor: generateColor('#0e1955', Object.keys(acc).length),
              backgroundColor: generateColor('#0e1955', Object.keys(acc).length),
              fill: false,
            };
          }
          const dateIndex = labels.indexOf(formatDate(item.date));
          if (dateIndex >= 0) {
            acc[roomName].data[dateIndex] = item.engaged_percentage * 100;
          }
          return acc;
        }, {});

        datasets.push(...Object.values(roomGroups));
      } else {
        // Default dataset if no room/site is specified
        datasets.push({
          label: 'Schedules Engaged (%)',
          data: labels.map(date => {
            const item = data.find(d => formatDate(d.date) === date);
            return item ? item.engaged_percentage * 100 : null;
          }),
          fill: false,
          backgroundColor: '#0e1955',
          borderColor: '#0e1955',
        });
      }

      setSchedulingData({
        labels,
        datasets,
      });

  
    } catch (error) {
      console.error('Error fetching trend data:', error);
    }
  };

  
  // Fetch utilization data
  const fetchUtilizationData = async (granularity, showBySite, showByRoom) => {
    try {
      const response = await axiosInstance.get('/api/utilization/', {
        params: {
          granularity,
          site: selectedSites.length > 0 ? selectedSites.join(',') : null,
          room: selectedRoom?.map(room => room.value).join(','),
          modality: selectedModality.length > 0 ? selectedModality.join(',') : null,
          start_date: specificStartDate ? specificStartDate.toISOString().split('T')[0] : formatDateFilter(),
          end_date: specificEndDate ? specificEndDate.toISOString().split('T')[0] : null,
          by_site: showBySite,
          by_room: showByRoom,
        }
      });
      const utilizationValues = response.data.map(item => item.total_utilization);
      const availabilityValues = response.data.map(item => item.total_availability);

      // Calculate total utilization
      const totalUtilizationSum = utilizationValues.reduce((sum, total_utilization) => sum + total_utilization, 0);
      const totalAvailabilitySum = availabilityValues.reduce((sum, total_availability) => sum + total_availability, 0);
      const overallUtilization = totalAvailabilitySum > 0 ? totalUtilizationSum / totalAvailabilitySum : 0;
      const { data } = response;
        // Format dates based on granularity (day, week, month)
      

      // Extract unique labels (dates based on granularity)
      const labels = Array.from(new Set(data.map(item => formatDate(item.date))));

      let datasets = [];

      if (showBySite) {
        // Group data by site and map utilization percentages by date
        const siteGroups = data.reduce((acc, item) => {
          const siteName = item.site_name;
          if (!acc[siteName]) {
            acc[siteName] = {
              label: `Utilization for Site: ${siteName}`,
              data: new Array(labels.length).fill(null), // Align by dates
              borderColor: generateColor('#0e1955', Object.keys(acc).length),
              backgroundColor: generateColor('#0e1955', Object.keys(acc).length),
              fill: false,
            };
          }
          const dateIndex = labels.indexOf(formatDate(item.date));
          if (dateIndex >= 0) {
            acc[siteName].data[dateIndex] = item.utilization_percentage * 100;
          }
          return acc;
        }, {});

        datasets.push(...Object.values(siteGroups));
      } else if (showByRoom) {
        // Group data by room and map utilization percentages by date
        const roomGroups = data.reduce((acc, item) => {
          const roomName = item.room_name;
          if (!acc[roomName]) {
            acc[roomName] = {
              label: `Utilization for Room: ${roomName}`,
              data: new Array(labels.length).fill(null), // Align by dates
              borderColor: generateColor('#0e1955', Object.keys(acc).length),
              backgroundColor: generateColor('#0e1955', Object.keys(acc).length),
              fill: false,
            };
          }
          const dateIndex = labels.indexOf(formatDate(item.date));
          if (dateIndex >= 0) {
            acc[roomName].data[dateIndex] = item.utilization_percentage * 100;
          }
          return acc;
        }, {});

        datasets.push(...Object.values(roomGroups));
      } else {
        // Default dataset if no room/site is specified
        datasets.push({
          label: 'Schedule Utilization (%)',
          data: labels.map(date => {
            const item = data.find(d => formatDate(d.date) === date);
            return item ? item.utilization_percentage * 100 : null;
          }),
          fill: false,
          backgroundColor: '#0e1955',
          borderColor: '#0e1955',
        });
      }


      setUtilizationData({
        labels,
        datasets,
      });

      setTotalUtilization(overallUtilization * 100); // Overall utilization as percentage

    } catch (error) {
      console.error('Error fetching utilization data:', error);
    }
  };

  function generateColor(baseColor = '#0e1955', index = 0) {
    const hexToHSL = (H) => {
      // Convert hex to RGB first
      let r = 0, g = 0, b = 0;
      if (H.length === 4) {
        r = parseInt(H[1] + H[1], 16);
        g = parseInt(H[2] + H[2], 16);
        b = parseInt(H[3] + H[3], 16);
      } else if (H.length === 7) {
        r = parseInt(H[1] + H[2], 16);
        g = parseInt(H[3] + H[4], 16);
        b = parseInt(H[5] + H[6], 16);
      }
      // Then convert RGB to HSL
      r /= 255;
      g /= 255;
      b /= 255;
      let cmin = Math.min(r, g, b),
          cmax = Math.max(r, g, b),
          delta = cmax - cmin,
          h = 0,
          s = 0,
          l = 0;
  
      if (delta === 0) h = 0;
      else if (cmax === r) h = ((g - b) / delta) % 6;
      else if (cmax === g) h = (b - r) / delta + 2;
      else h = (r - g) / delta + 4;
  
      h = Math.round(h * 60);
  
      if (h < 0) h += 360;
  
      l = (cmax + cmin) / 2;
  
      s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
      s = +(s * 100).toFixed(1);
      l = +(l * 100).toFixed(1);
  
      return { h, s, l };
    };
  
    const hslToHex = (h, s, l) => {
      s /= 100;
      l /= 100;
      let c = (1 - Math.abs(2 * l - 1)) * s,
          x = c * (1 - Math.abs((h / 60) % 2 - 1)),
          m = l - c / 2,
          r = 0,
          g = 0,
          b = 0;
  
      if (0 <= h && h < 60) {
        r = c;
        g = x;
        b = 0;
      } else if (60 <= h && h < 120) {
        r = x;
        g = c;
        b = 0;
      } else if (120 <= h && h < 180) {
        r = 0;
        g = c;
        b = x;
      } else if (180 <= h && h < 240) {
        r = 0;
        g = x;
        b = c;
      } else if (240 <= h && h < 300) {
        r = x;
        g = 0;
        b = c;
      } else if (300 <= h && h < 360) {
        r = c;
        g = 0;
        b = x;
      }
  
      r = Math.round((r + m) * 255);
      g = Math.round((g + m) * 255);
      b = Math.round((b + m) * 255);
  
      return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
    };
  
    // Convert base color to HSL
    const { h, s, l } = hexToHSL(baseColor);
  
    // Create variations by adjusting hue and lightness
    const hueVariation = (h + index * 30) % 360; // Shift hue by 30 degrees per line
    const lightnessVariation = Math.min(Math.max(l + index * 10, 10), 90); // Adjust lightness
  
    return hslToHex(hueVariation, s, lightnessVariation);
  };
  

  const formatDateFilter = () => {
    const today = new Date();
    let startDate;
    switch (relativePeriodUnit) {
      case 'day':
        startDate = new Date(today.setDate(today.getDate() - relativePeriodNumber));
        break;
      case 'week':
        startDate = new Date(today.setDate(today.getDate() - (relativePeriodNumber * 7)));
        break;
      case 'month':
        startDate = new Date(today.setMonth(today.getMonth() - relativePeriodNumber));
        break;
      case 'quarter':
        startDate = new Date(today.setMonth(today.getMonth() - (relativePeriodNumber * 3)));
        break;
      case 'year':
        startDate = new Date(today.setFullYear(today.getFullYear() - relativePeriodNumber));
        break;
      default:
        startDate = null;
    }
    return startDate ? startDate.toISOString().split('T')[0] : null;
  };

  const handleGranularityChange = (newGranularity) => {
    setGranularity(newGranularity);
  };

  const handleRelativePeriodNumberChange = (event) => {
    setRelativePeriodNumber(event.target.value);
  };

  const handleRelativePeriodUnitChange = (event) => {
    setRelativePeriodUnit(event.target.value);
  };

  const handleSpecificStartDateChange = (date) => {
    setSpecificStartDate(date);
  };

  const handleSpecificEndDateChange = (date) => {
    setSpecificEndDate(date);
  };

  const fetchPatientSuggestions = async (inputValue) => {
    try {
      const response = await axiosInstance.get('/api/patient-suggestions/', { params: { query: inputValue } });
      return response.data.map(patient => ({
        label: `${patient.first_name} ${patient.last_name}`,
        value: patient.id
      }));
    } catch (error) {
      console.error('Error fetching patient suggestions:', error);
      return [];
    }
  };
  const fetchSites = async () => {
      try {
          const response = await axiosInstance.get('/api/sites/');
          setSites(response.data);
      } catch (error) {
          console.error('Error fetching sites:', error);
      }
  };


  const fetchModalities = async () => {
      try {
        const response = await axiosInstance.get('/api/modalities/');
        setModalities(response.data);
      } catch (error) {
        console.error('Error fetching modalities', error);
      }
  };

  const fetchRooms = async (inputValue) => {
      try {
        const response = await axiosInstance.get('/api/rooms/', {
          params: { search: inputValue },
        });
        return response.data.map(room => ({
          value: room.id,
          label: room.name
        }));
      } catch (error) {
        console.error('Error fetching rooms', error);
        return [];
      }
  };


  const handlePatientChange = (selectedPatient) => {
    setSelectedPatient(selectedPatient);
  };

  const handleSiteFilterChange = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
    setSelectedSites(selectedOptions);
  };

  const handleRoomChange = (selectedRooms) => {
    setSelectedRoom(selectedRooms);
  };

  const handleModalityFilterChange = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
    setSelectedModalities(selectedOptions);
  };
  
  const totalCommunications = communications.length;

  const trendOptions = {
    scales: {
      x: {
        type: 'time',
        time: {
          unit: granularity === 'day' ? 'day' : granularity === 'week' ? 'week' : 'month',
        }
      },
      y: {
        beginAtZero: true, // Numeric Y-axis for trend data
      }
    }
  };

  const utilizationOptions = {
    scales: {
      x: {
        type: 'time',
        time: {
          unit: granularity === 'day' ? 'day' : granularity === 'week' ? 'week' : 'month',
        }
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => `${value}%`, // Showing as percentage for utilization
        }
      }
    }
  };

  const handleToggleBySite = () => {
    setShowByRoom(false);
    setShowBySite(!showBySite);
  };

  const handleToggleByRoom = () => {
    setShowBySite(false);
    setShowByRoom(!showByRoom);
  };

  const downloadChartData = (data, fileName) => {
    // Extract labels and datasets
    const labels = data.labels;
    const datasets = data.datasets;
  
    // Prepare data for CSV export
    const csvData = [ 
      ['Label', ...datasets.map(dataset => dataset.label)], // Header row with dataset names
      ...labels.map((label, index) => [
        label, 
        ...datasets.map(dataset => dataset.data[index] || '') // Each dataset's value for the label
      ])
    ];
  
    // Convert the prepared data to CSV format
    const csv = Papa.unparse(csvData);
  
    // Create a blob and trigger the download
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, fileName);
  };

  const toggleSidebar = () => {
    setCollapsed(!collapsed); // Toggle sidebar collapse state
  };

  // Clear all filters
  const clearFilters = () => {
    setSelectedSites([]);
    setSelectedRoom([]);
    setSelectedModalities([]);
    setRelativePeriodNumber('');
    setRelativePeriodUnit('');
    setSpecificStartDate(null);
    setSpecificEndDate(null);
  };


  return (
    <div className="container-fluid">
      <div className="d-flex">
        {/* Sidebar Toolbar */}
        <div
          className={`sidebar-toolbar p-3 border-end bg-light ${collapsed ? 'collapsed' : ''}`}
          style={{
            width: collapsed ? '60px' : '300px',
            transition: 'width 0.3s',
            display: 'flex',
            flexDirection: 'column',
            alignItems: collapsed ? 'center' : 'flex-start'
          }}
        >
          {/* Filters label that is always visible when collapsed */}
          {collapsed && (
            <div
              style={{
                color: '#0f1955', // Bootstrap primary blue or custom color for visibility
                marginBottom: '10px',
                textAlign: 'center',
                fontSize: '14px',
                fontWeight: 'bold',
              }}
            >
              Filters
            </div>
          )}
          {/* Toggle button */}
          <Button
            variant="primary"
            onClick={toggleSidebar}
            className="mb-3"
            style={{
              width: collapsed ? '100%' : '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: collapsed ? 'column' : 'row'
            }}
          >
            {collapsed ? (
              <>
                <span>&gt;&gt;</span>
              </>
            ) : (
              <>
                <span>&lt;&lt;</span>
              </>
            )}
          </Button>

          {/* Collapse content */}
          <Collapse in={!collapsed}>
            <div>
              <h4 className="text-center">Filters</h4>

              <Form.Group className="mb-3">
                <Form.Label>Sites</Form.Label>
                <Form.Control
                  as="select"
                  multiple
                  value={selectedSites}
                  onChange={handleSiteFilterChange}
                >
                  {sites.map((site) => (
                    <option key={site.id} value={site.id}>
                      {site.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Rooms</Form.Label>
                <AsyncSelect
                  isMulti
                  cacheOptions
                  loadOptions={fetchRooms}
                  onChange={handleRoomChange}
                  value={selectedRoom}
                  isClearable
                  placeholder="Select rooms"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Modalities</Form.Label>
                <Form.Control
                  as="select"
                  multiple
                  value={selectedModality}
                  onChange={handleModalityFilterChange}
                >
                  {modalities.map((modality) => (
                    <option key={modality.id} value={modality.id}>
                      {modality.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Last</Form.Label>
                <Form.Control
                  type="number"
                  value={relativePeriodNumber}
                  onChange={handleRelativePeriodNumberChange}
                  placeholder="Number of periods"
                />
                <Form.Control
                  as="select"
                  value={relativePeriodUnit}
                  onChange={handleRelativePeriodUnitChange}
                  className="mt-2"
                >
                  <option value="day">Days</option>
                  <option value="week">Weeks</option>
                  <option value="month">Months</option>
                  <option value="quarter">Quarters</option>
                  <option value="year">Years</option>
                </Form.Control>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Specific Scheduled Date Range</Form.Label>
                <DatePicker
                  selected={specificStartDate}
                  onChange={handleSpecificStartDateChange}
                  dateFormat="yyyy/MM/dd"
                  placeholderText="Start date"
                  className="form-control mb-2"
                />
                <DatePicker
                  selected={specificEndDate}
                  onChange={handleSpecificEndDateChange}
                  dateFormat="yyyy/MM/dd"
                  placeholderText="End date"
                  className="form-control"
                />
              </Form.Group>
              {/* Clear Filters Button */}
              <Button
                variant="secondary"
                onClick={clearFilters}
                className="mt-3"
                style={{ width: '100%' }}
              >
                Reset Filters
              </Button>
            </div>
          </Collapse>
        </div>


        {/* Main Content */}
        <div className="flex-grow-1 p-3">
          <h3 className="mb-4">Analytics</h3>
          <div className="mb-4">
            <h5>Total Utilization</h5>
            <div className="d-flex align-items-center">
              <div className="mr-2" style={{ marginRight: '10px' }}>Utilization:</div>
              <div>{totalUtilization.toFixed(2)}%</div>
            </div>
          </div>
          <div className="mb-4">
            <h5>Utilization Trend</h5>

            {/* Flex container for target utilization input and download button */}
            <div className="d-flex justify-content-between align-items-center mb-3">
                {/* Render target setting input only if the user is a staff member */}
                {isStaff && (
                  <div className="d-flex align-items-center">
                    <input
                      type="number"
                      placeholder="Set Target Utilization (%)"
                      value={targetUtilization || ''}
                      onChange={(e) => setTargetUtilization(e.target.value)}
                      className="form-control mr-2"
                      style={{ width: '200px' }} 
                    />
                    <button onClick={handleTargetSubmit} className="btn btn-primary">Set Target</button>
                  </div>
                )}
              <div>
                <button className={`btn ${showBySite ? 'btn-primary' : 'btn-outline-primary'}`} onClick={handleToggleBySite}>By Site</button>
                <button className={`btn ${showByRoom ? 'btn-primary' : 'btn-outline-primary'} ml-2`} onClick={handleToggleByRoom}>By Room</button>
                <button className="btn btn-primary" onClick={() => handleGranularityChange('day')}>Day</button>
                <button className="btn btn-primary" onClick={() => handleGranularityChange('week')}>Week</button>
                <button className="btn btn-primary" onClick={() => handleGranularityChange('month')}>Month</button>
              </div>

              {/* Download button */}
              <button onClick={() => downloadChartData(utilizationData, 'utilization-trend.csv')} className="btn btn-primary btn-sm">
                Download Data
              </button>
            </div>

            {/* Line chart for utilization with max height and scrolling */}
            <div style={{ maxHeight: '400px', width: '100%', overflowY: 'auto' }}>
              <Line data={utilizationData} options={utilizationOptions} />
            </div>
          </div>
          <div className="mb-4">
            <h5>Patient Scheduling Engagement</h5>
            <div className="d-flex align-items-center">
            <div className="mr-2" style={{ marginRight: '10px' }}>% of Appointments with Communications:</div>
              <div>{totalEngagement}%</div>
            </div>
          </div>
          <div className="mb-4">
            <h5>% of Appointments with Communications Trend</h5>
            <div>
                <button className={`btn ${showBySite ? 'btn-primary' : 'btn-outline-primary'}`} onClick={handleToggleBySite}>By Site</button>
                <button className={`btn ${showByRoom ? 'btn-primary' : 'btn-outline-primary'} ml-2`} onClick={handleToggleByRoom}>By Room</button>
                <button className="btn btn-primary" onClick={() => handleGranularityChange('day')}>Day</button>
                <button className="btn btn-primary" onClick={() => handleGranularityChange('week')}>Week</button>
                <button className="btn btn-primary" onClick={() => handleGranularityChange('month')}>Month</button>
            </div>
            <button onClick={() => downloadChartData(schedulingData, '%-appointments-trend.csv')} className="btn btn-primary btn-sm">Download Data</button>
            <div style={{ maxHeight: '400px', width: '100%', overflowY: 'auto' }}>
              <Line data={schedulingData} options={utilizationOptions} />
            </div>
          </div>
          <div className="mb-4" ref={chartEndRef}>
            <h5>Communication Trend</h5>
            <div className="d-flex align-items-center">
            <div className="mr-2" style={{ marginRight: '10px' }}>Total patient communications: </div>
              <div>{totalCommunications}</div>
            </div>
            <h5>Total patient communications trend by sent at date:</h5>
            <div>
                <button className={`btn ${showBySite ? 'btn-primary' : 'btn-outline-primary'}`} onClick={handleToggleBySite}>By Site</button>
                <button className={`btn ${showByRoom ? 'btn-primary' : 'btn-outline-primary'} ml-2`} onClick={handleToggleByRoom}>By Room</button>
                <button className="btn btn-primary" onClick={() => handleGranularityChange('day')}>Day</button>
                <button className="btn btn-primary" onClick={() => handleGranularityChange('week')}>Week</button>
                <button className="btn btn-primary" onClick={() => handleGranularityChange('month')}>Month</button>
            </div>
            <button onClick={() => downloadChartData(trendData, 'communication-trend.csv')} className="btn btn-primary btn-sm">Download Data</button>
            <div style={{ maxHeight: '400px', width: '100%', overflowY: 'auto' }}>
              <Line data={trendData} options={trendOptions} />
            </div>
          </div>
        </div>
      </div>
      {/* Back Button */}
      <button className="btn btn-secondary mb-3" onClick={handleBack}>
        Back
      </button>
    </div>
  );
};

export default CommunicationAnalytics;
