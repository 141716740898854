import React, { useState } from 'react';
import axiosInstance from '../utils/auth';  // Adjust the import path as necessary
import { useNavigate } from 'react-router-dom';

const TestHL7Message = () => {
    const navigate = useNavigate();
    const [hl7Message, setHl7Message] = useState('');
    const [results, setResults] = useState(null);
    const [testSuccess, setTestSuccess] = useState(false);

    const handleTestMessage = async () => {
        try {
            console.log(hl7Message)
            // Add MLLP framing characters
            const framedMessage = `\x0b${hl7Message}\x1c\x0d`;

            // Convert to UTF-8 encoded binary data
            const encoder = new TextEncoder();
            const encodedMessage = encoder.encode(framedMessage);

            const response = await axiosInstance.post('/api/test-hl7-message/', encodedMessage, {
                headers: {
                    'Content-Type': 'application/octet-stream'
                },
                responseType: 'json'
            });

            setResults(response.data);
            setTestSuccess(true);
        } catch (error) {
            console.error('Error testing HL7 message', error);
            setTestSuccess(false);
        }
    };

    const sendToProduction = async () => {
        try {
            await axiosInstance.post('/api/send-to-production/');
            navigate('/success'); // Update with your success route or message
        } catch (error) {
            console.error('Error sending configuration to production', error);
        }
    };

    return (
        <div className="container">
            <h2>Test HL7 Message</h2>
            <textarea 
                value={hl7Message} 
                onChange={(e) => setHl7Message(e.target.value)} 
                rows="10" 
                className="form-control" 
                placeholder="Enter HL7 Message" 
            />
            <button className="btn btn-primary mt-3" onClick={handleTestMessage}>Send Test Message</button>
            {results && (
                <div className="mt-3">
                    <h3>Results</h3>
                    <pre>{JSON.stringify(results, null, 2)}</pre>
                    <button className="btn btn-secondary" onClick={() => setHl7Message('')}>Test Another Message</button>
                    {testSuccess && <button className="btn btn-success" onClick={sendToProduction}>Send to Production</button>}
                </div>
            )}
        </div>
    );
};

export default TestHL7Message;