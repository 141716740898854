import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/auth';

const CommunicationReplyTable = () => {
    const [unrespondedCommunications, setUnrespondedCommunications] = useState([]);
    const navigate = useNavigate();
  
    useEffect(() => {
      // Fetch communications and unresponded count
      const fetchCommunications = async () => {
        try {
          const response = await axiosInstance.get('/api/communications/');
          const data = response.data;
      
          // Filter for unresponded patient replies
          const unansweredReplies = data.combined_data.filter(comm => comm.is_unresponded_patient_reply);
      
          setUnrespondedCommunications(unansweredReplies);
          //setUnrespondedCount(data.unresponded_patient_replies_count);
        } catch (error) {
          console.error('Error fetching communications:', error);
        }
      };
  
      fetchCommunications();
    }, []);
  

  return (
    <div className="container">
      {/* Table of unanswered communications */}
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Patient Name</th>
            <th>Message Type</th>
            <th>Campaign</th>
            <th>Sent At</th>
          </tr>
        </thead>
        <tbody>
          {unrespondedCommunications.map(communication => (
            <tr
              key={communication.id}
              onClick={() =>
                navigate(`/communications/${communication.communication_type}/${communication.id}/`)
              }
              style={{ cursor: 'pointer' }}
            >
              <td>{communication.patient_name}</td>
              <td>{communication.communication_type || 'Email'}</td>
              <td>{communication.subject || communication.message}</td>
              <td>{new Date(communication.sent_at).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CommunicationReplyTable;
