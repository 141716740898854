// usFederalHolidays.js

export const addFederalHolidays = () => {
    const currentYear = new Date().getFullYear();
    const nextYear = currentYear + 1;

    // Example US Federal Holidays for current year and next year.
    const holidays = [
        { name: 'New Year\'s Day', date: `${currentYear}-01-01` },
        { name: 'Martin Luther King Jr. Day', date: `${currentYear}-01-15` },
        { name: 'Juneteenth National Independence Day', date: `${currentYear}-06-19` },
        { name: 'Independence Day', date: `${currentYear}-07-04` },
        { name: 'Labor Day', date: `${currentYear}-09-02` },
        { name: 'Columbus Day', date: `${currentYear}-10-14` },
        { name: 'Veterans Day', date: `${currentYear}-11-11` },
        { name: 'Thanksgiving Day', date: `${currentYear}-11-24` },
        { name: 'Christmas Day', date: `${currentYear}-12-25` },
        { name: 'New Year\'s Day', date: `${nextYear}-01-01` },
        { name: 'Martin Luther King Jr. Day', date: `${nextYear}-01-15` },
        { name: 'Juneteenth National Independence Day', date: `${nextYear}-06-19` },
        { name: 'Independence Day', date: `${nextYear}-07-04` },
        { name: 'Labor Day', date: `${nextYear}-09-02` },
        { name: 'Columbus Day', date: `${nextYear}-10-14` },
        { name: 'Veterans Day', date: `${nextYear}-11-11` },
        { name: 'Thanksgiving Day', date: `${nextYear}-11-24` },
        { name: 'Christmas Day', date: `${nextYear}-12-25` },
    ];
    return holidays;
};

