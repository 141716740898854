import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '../utils/auth';
import { Doughnut, Bar } from 'react-chartjs-2';
import { NavLink } from 'react-router-dom';
import { Form, Collapse, Button } from 'react-bootstrap';
import { Chart, ArcElement, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js'; // Import necessary chart elements

// Register the elements
Chart.register(ArcElement, BarElement, CategoryScale, LinearScale, Tooltip, Legend);
// Custom plugin to display text in the center of the doughnut

const HomePage = ({ unansweredRepliesCount, email }) => {
  const [communications, setCommunications] = useState([]);
  const [totalUtilization, setTotalUtilization] = useState(0); // For overall utilization callout
  const [totalEngagement, setOverallSchedulingMetric] = useState(0);
  const [averageTimeToService, setAverageTimeToService] = useState(null);



  useEffect(() => {
    fetchCommunications();
    fetchUtilizationData();
    fetchSchedulingData();
    fetchAverageTimeToService();
    if (email) {
        localStorage.setItem('email', email);
      }
    }, [email] );
  // Helper function to calculate the last 30 days range
  const centerTextPlugin = {
    id: 'centerText',
    beforeDraw: function (chart) {
      const { width, height, ctx } = chart;
      const centerTextOption = chart.config.options.plugins?.centerText; // Safely access centerText option
  
      if (centerTextOption && centerTextOption.text) { // Check if centerText and its text property are defined
        const text = `${centerTextOption.text}`;
        ctx.restore();
        const fontSize = (height / 150).toFixed(2); // Adjust the font size as needed
        ctx.font = `bold ${fontSize}em Arial`;
        ctx.fillStyle = '#0e1955'; // Text color
        ctx.textBaseline = 'middle';
        const textX = Math.round((width - ctx.measureText(text).width) / 2);
        const textY = height / 2;
        ctx.fillText(text, textX, textY);
        ctx.save();
      }
    },
  };
  
  const fetchAverageTimeToService = async () => {
    try {
      const response = await axiosInstance.get('api/average-time-to-service/');
      setAverageTimeToService(response.data.average_time_to_service_days);
      //setLoading(false);
    } catch (err) {
      console.error('Error fetching average time to service:', err);
      //setError('Failed to load data');
      //setLoading(false);
    }
  };

  const getLast30DaysRange = () => {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - 30);

    return {
      start_date: startDate.toISOString().split('T')[0],
      end_date: endDate.toISOString().split('T')[0]
    };
  };
  const savedEmail = localStorage.getItem('email');
  
  // Fetch communications based on filters
  const fetchCommunications = async ( ) => {
    const { start_date, end_date } = getLast30DaysRange(); // Last 30 days date range
    try {
      const response = await axiosInstance.get('/api/communications/', {
        params: {
            patient: null,           // Fixed to null
            site: null,              // Fixed to null
            room: [],              // Fixed to null
            modality: [],          // Fixed to null
            start_date: start_date,  // Last 30 days start date
            end_date: end_date,      // Last 30 days end date
            granularity: 'day',      // Fixed granularity to day
            showBySite: false,       // Set to false
            showByRoom: false        // Set to false
        }
      });
      // Assuming the response structure is { combined_data: [], unresponded_patient_replies_count: 0 }
      const { combined_data, unresponded_patient_replies_count } = response.data;

      // Set the communications data (combined_data) in your state
      setCommunications(combined_data);
    } catch (error) {
      console.error('Error fetching communications:', error);
    }
  };
  
  // Fetch utilization data
  const fetchUtilizationData = async () => {
    const { start_date, end_date } = getLast30DaysRange(); // Last 30 days date range
    try {
      const response = await axiosInstance.get('/api/utilization/', {
        params: {
            patient: null,           // Fixed to null
            site: null,              // Fixed to null
            room: [],              // Fixed to null
            modality: [],          // Fixed to null
            start_date: start_date,  // Last 30 days start date
            end_date: end_date,      // Last 30 days end date
            granularity: 'day',      // Fixed granularity to day
            showBySite: false,       // Set to false
            showByRoom: false        // Set to false
        }
      });
      const utilizationValues = response.data.map(item => item.total_utilization);
      const availabilityValues = response.data.map(item => item.total_availability);

      // Calculate total utilization
      const totalUtilizationSum = utilizationValues.reduce((sum, total_utilization) => sum + total_utilization, 0);
      const totalAvailabilitySum = availabilityValues.reduce((sum, total_availability) => sum + total_availability, 0);
      const overallUtilization = totalAvailabilitySum > 0 ? totalUtilizationSum / totalAvailabilitySum : 0;
      const { data } = response;
        // Format dates based on granularity (day, week, month)
      setTotalUtilization(overallUtilization * 100); // Overall utilization as percentage

    } catch (error) {
      console.error('Error fetching utilization data:', error);
    }
  };

  const fetchSchedulingData = async () => {
    const { start_date, end_date } = getLast30DaysRange(); // Last 30 days date range
    try {
      const response = await axiosInstance.get('/api/communications/scheduling-trend/', {
        params: {
          patient: null,           // Fixed to null
            site: null,              // Fixed to null
            room: [],              // Fixed to null
            modality: [],          // Fixed to null
            start_date: start_date,  // Last 30 days start date
            end_date: end_date,      // Last 30 days end date
            granularity: 'day',      // Fixed granularity to day
            showBySite: false,       // Set to false
            showByRoom: false        // Set to false
        }
      });
      // Calculate overall percentage
      const totalAppointments = response.data.reduce((sum, item) => sum + item.total_appointments, 0);
      const totalAppointmentsWithCommunications = response.data.reduce((sum, item) => sum + item.appointments_with_communications, 0);
      const overallPercentage = totalAppointments > 0
        ? (totalAppointmentsWithCommunications / totalAppointments) * 100
        : 0;
  
      setOverallSchedulingMetric(overallPercentage.toFixed(2));  // Set overall percentage

  
    } catch (error) {
      console.error('Error fetching trend data:', error);
    }
  };
  


  const utilizationChartData = {
    labels: ['Utilized', 'Available'],
    datasets: [
      {
        data: [totalUtilization, 100 - totalUtilization],
        backgroundColor: ['#0e1955', '#e0e0e0'],
      },
    ],
  };

  const engagementChartData = {
    labels: ['Engaged', 'Unengaged'],
    datasets: [
      {
        data: [totalEngagement, 100 - totalEngagement],
        backgroundColor: ['#0e1955', '#e0e0e0'],
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.label}: ${tooltipItem.raw}%`;
          },
        },
      },
      centerText: {
        text: '', // Placeholder for dynamic text
      },
    },
  };
  
  return (
    <div className="container-fluid">
      <div className="d-flex">
        {/* Main Content */}
        <div className="main-content p-4" >
            {/* Greeting */}
            <div className="mb-4">
                <h2>Hello {savedEmail}, welcome to ScanBooks!</h2>
            </div>
            {/* Section 1: Schedule Utilization */}
            <div className="mb-4 bg-light p-4 border hover-effect">
            <h3>Schedule Utilization</h3>
            <p>
                Schedule Utilization is a core radiology performance metric that indicates how much of your 
                scheduling availability is being turned into completed exams. 
                Engaging patients thoughtfully within your scheduling workflow enhances the effective use of your valuable staff and room time.
                Increasing schedule utilization also decreases patient delays for service.
                <strong> Your schedule utilization for the last 30 days is {totalUtilization.toFixed(2)}%.</strong>
              </p>
                <div className="center-chart" style={{ width: '250px', height: '250px' }}>
                  <Doughnut
                    data={utilizationChartData}
                    options={{
                      ...chartOptions,
                      plugins: {
                        ...chartOptions.plugins,
                        centerText: { text: `${totalUtilization.toFixed(2)}%` }, // Center text for utilization chart
                      },
                    }}
                    plugins={[centerTextPlugin]} // Add the custom plugin here
                  />
                </div>
                <NavLink
                        to="/analytics"
                        className="nav-link sidebar-link"
                        activeClassName="active"
                    >
                        Monitor and Analyze your schedule utilization real-time &gt;
                </NavLink>
            
            </div>

            {/* Section 2: Patient Engagement */}
            <div className="mb-4 bg-light p-4 border hover-effect">
            <h3>Patient Engagement</h3>
            <p>
                Patient engagement is a core tenant of increasing schedule utilization and patient satisfaction.
                <strong>  {totalEngagement}% of engaged patients have completed in the last 30 days. </strong>
            </p>
                <div className="center-chart" style={{ width: '250px', height: '250px' }}>
                  <Doughnut
                    data={engagementChartData}
                    options={{
                      ...chartOptions,
                      plugins: {
                        ...chartOptions.plugins,
                        centerText: { text: `${totalEngagement}%` }, // Center text for engagement chart
                      },
                    }}
                    plugins={[centerTextPlugin]} // Add the custom plugin here
                  />
                </div>
                <NavLink
                        to="/communications"
                        className="nav-link sidebar-link"
                        activeClassName="active"
                    >
                        Go to the patient inbox &gt; {unansweredRepliesCount > 0 && (
                          <span className="reply-bubble">{unansweredRepliesCount}</span>
                        )}
                </NavLink>
            
            </div>
            {/* Section 3: Cancels Waitlist */}
            <div className="mb-4 bg-light p-4 border hover-effect">
            <h3>Average Patient Time to Service</h3>
            <p>
              Reducing the time it takes for a patient to receive a radiology procedure is critical for timely diagnosis and treatment, 
              which can significantly improve patient outcomes. Delays may exacerbate health issues or increase anxiety for patients awaiting results. 
              Implementing a cancellations waitlist can help optimize scheduling by quickly filling open slots, reducing overall wait times and ensuring that radiology resources are used efficiently, ultimately providing faster access to care for patients.
                <strong> The average patient time to service in the last 30 days is {averageTimeToService !== null ? `${averageTimeToService} days` : 'No data available'}.</strong>
            </p>
              {/* Callout for cancels replaced */}
              <div className="callout-container d-flex justify-content-center align-items-center" style={{ padding: '50px 0', textAlign: 'center' }}>
                  <div className="callout" style={{ fontSize: '36px', fontWeight: 'bold', color: '#0e1955' }}>
                  Average Time to Service: {averageTimeToService !== null ? `${averageTimeToService} days` : 'No data available'}
                  </div>
              </div>
                <NavLink
                        to="/cancels-waitlist"
                        className="nav-link sidebar-link"
                        activeClassName="active"
                    >
                        Go to the cancels waitlist &gt;
                </NavLink>  
            </div>

            {/* Section 3: Helpful Links */}
           {/* <div className="mb-4 bg-light p-4 border">
            <h3>Helpful Links</h3>
            <p>
                Here are some helpful links that show you exactly how to navigate and get the best use out of ScanBooks applications.
            </p>
            <ul>
                <li><a href="/help/how-to-analyze">How to use our Analytics dashboard</a></li>
                <li><a href="/help/cancels-waitlist">How to monitor and manage the cancels waitlist</a></li>
                <li><a href="/help/how-to-communicate">How to reply to patient inquiries</a></li>
            </ul>
            </div> */}
        </div>
    </div>
  </div>
  );
};

export default HomePage;
