// src/components/SuccessPage.js

import React from 'react';

const SuccessPage = () => {
    return (
        <div>
            <h1>Successfully Sent Configuration to Production!</h1>
        </div>
    );
};

export default SuccessPage;
