import React, { useState, useEffect } from 'react';
import axiosInstance from '../utils/auth';
import { Table, Button, Form, Alert, Modal } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import { useNavigate } from 'react-router-dom';  // Import useNavigate for React Router v6

const ProcedureManagement = () => {
    const [procedures, setProcedures] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedProcedure, setSelectedProcedure] = useState(null);
    const [file, setFile] = useState(null);
    const [selectedRoom, setSelectedRoom] = useState('');
    const navigate = useNavigate(); // Initialize useNavigate for navigation
    // Navigate back to the previous page
    const handleBack = () => {
      navigate(-1);
    };

//Fetch Procedures
    const fetchProcedures = async () => {
        try {
            const response = await axiosInstance.get('/api/procedures/');
            setProcedures(response.data);
        } catch (error) {
            console.error('Error fetching procedures', error);
        }
        };
// Load room options based on search query
    const loadRoomOptions = async (inputValue) => {
      try {
          const response = await axiosInstance.get('/api/rooms/', {params: {query: inputValue}});
          return response.data.map(room => ({ value: room.id, label: room.name }));
      } catch (error) {
          console.error('Error fetching rooms', error);
          return [];
      }
    };

    useEffect(() => {
        fetchProcedures();
        }, []);

///Handle Procedure book csv upload.
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
        setFile(selectedFile);
        }
    };

    const handleUploadButtonClick = async () => {
        if (!file) {
            setError('Please select a file first');
            return;
        }

        let formData = new FormData();
            formData.append('file', file);
        try {
        await axiosInstance.post('/api/procedures/upload/', formData);
        fetchProcedures();
        } catch (error) {
        setError('Error uploading procedures');
        }
    };

  const handleDeleteProcedure = async (procedureId) => {
    try {
      await axiosInstance.delete(`/api/procedures/delete/${procedureId}/`);
      setProcedures(procedures.filter((procedure) => procedure.id !== procedureId));
    } catch (error) {
      console.error('Error deleting procedure', error);
    }
  };

  const handleShowModal = (procedure) => {
    setSelectedProcedure(procedure);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedProcedure(null);
  };

  const handleRoomTagChange = async (newRooms) => {
    const updatedProcedure = { ...selectedProcedure, rooms: newRooms };
    try {
      await axiosInstance.put(`/api/procedures/${selectedProcedure.id}/`, updatedProcedure);
      fetchProcedures();
      handleCloseModal();
    } catch (error) {
      setError('Error updating procedure rooms');
    }
  };
  const handleAddRoom = (room) => {
    if (selectedProcedure.rooms.some(r => r.id === room.value)) {
      setError('Room already added');
      return;
    }
    const newRooms = [
        ...selectedProcedure.rooms.map(room => room.id), // Extract existing room IDs
        room.value // Add the ID of the new room
      ];
    handleRoomTagChange(newRooms);
  };

  const handleRemoveRoom = (roomId) => {
    const newRooms = selectedProcedure.rooms
        .filter(room => room.id !== roomId)
        .map(room => room.id);
    handleRoomTagChange(newRooms);
  };

  const handleRemoveRoomQuick =  async (procedure, roomId) => {
      const newRooms = procedure.rooms
        .filter(room => room.id !== roomId)
        .map(room => room.id);
        const updatedProcedure = { ...procedure, rooms: newRooms };
        try {
            await axiosInstance.put(`/api/procedures/${procedure.id}/`, updatedProcedure);
            fetchProcedures();
            handleCloseModal();
        } catch (error) {
            setError('Error updating procedure rooms');
        }
    };

  return (
    <div className="container mt-5">
      <h2>Procedure Management</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      <input type="file" name="file" label='Upload CSV' labelPosition='right' placeholder='UploadCSV...' onChange={handleFileChange}
        />
      <Button onClick={handleUploadButtonClick} className="mt-3">
        Upload
      </Button>

      <h3>Existing Procedures</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Procedure Name</th>
            <th>Rooms</th>
            <th>Modality</th>
            <th>Scheduled Slot Duration</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {procedures.map((procedure) => (
            <tr key={procedure.id}>
              <td>{procedure.procedure_name}</td>
              <td>
                {procedure.rooms.map(room => (
                  <Button
                    key={room.id}
                    variant="secondary"
                    className="mr-1 mb-1"
                    onClick={() => handleRemoveRoomQuick(procedure, room.id)}
                  >
                    {room.name} <span className="ml-2">&times;</span>
                  </Button>
                ))}
              </td>
              <td>{procedure.modality.name}</td>
              <td>{procedure.scheduled_slot_duration}</td>
              <td>
                <Button
                  variant="warning"
                  onClick={() => handleShowModal(procedure)}
                >
                  Tag Rooms
                </Button>{' '}
                <Button
                  variant="danger"
                  onClick={() => handleDeleteProcedure(procedure.id)}
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Tag Rooms for {selectedProcedure?.procedure_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="rooms">
            <Form.Label>Rooms</Form.Label>
            <div>
              {selectedProcedure?.rooms.map(room => (
                <Button
                  key={room.id}
                  variant="secondary"
                  className="mr-1 mb-1"
                  onClick={() => handleRemoveRoom(room.id)}
                >
                  {room.name} <span className="ml-2">&times;</span>
                </Button>
              ))}
            </div>
            <AsyncSelect
            cacheOptions
            loadOptions={loadRoomOptions}
            onChange={(selected) => setSelectedRoom(selected)}
            value={selectedRoom}
            placeholder="Enter room name"
            isClearable
            />
            <Button variant="primary" className="mt-2" onClick={() => handleAddRoom(selectedRoom)}>
              Add Room
            </Button>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Back Button */}
      <button className="btn btn-secondary mb-3" onClick={handleBack}>
        Back
      </button>
    </div>
  );
};

export default ProcedureManagement;
