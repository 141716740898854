import React, { useState } from 'react';
import axiosInstance from '../utils/auth';  // Adjust the import path as necessary
import { useNavigate } from 'react-router-dom';


// Define valid HL7 segments
const VALID_HL7_SEGMENTS = [
    'PID', 'MSH', 'OBR', 'ORC', 'AL1', 'DG1', 'GT1', 'IN1', 'NK1', 'PV1', 'SCH', 'AIS'
];

const HL7Configuration = () => {
    const navigate = useNavigate();
    const [config, setConfig] = useState({
        procedure_name: { segment: 'AIS', field: 2, subfield: 0, note: 'Procedure Name', description: 'The name of the procedure.' },
        patient_id: { segment: 'PID', field: 3, subfield: 0, note: 'Patient ID', description: 'Unique identifier for the patient.' },
        procedure_duration: { segment: 'SCH', field: 14, subfield: 0, note: 'Procedure Duration', description: 'Duration of the procedure.' },
        site: { segment: 'PID', field: 6, subfield: 1, note: 'Site', description: 'Location where the procedure takes place.' },
        room: { segment: 'SCH', field: 16, subfield: 3, note: 'Room', description: 'Room where the procedure is conducted.' },
        modality: { segment: 'SCH', field: 17, subfield: 1, note: 'Modality', description: 'Type of procedure or imaging modality.' },
        scheduled_start_datetime: { segment: 'SCH', field: 10, subfield: 0, note: 'Scheduled Start Datetime', description: 'Start date and time of the procedure.' },
        first_name: { segment: 'PID', field: 5, subfield: 1, note: 'First Name', description: 'Patient’s first name.' },
        last_name: { segment: 'PID', field: 5, subfield: 0, note: 'Last Name', description: 'Patient’s last name.' },
        dob: { segment: 'PID', field: 7, subfield: 0, note: 'Date of Birth', description: 'Patient’s date of birth.' },
        gender: { segment: 'PID', field: 8, subfield: 0, note: 'Gender', description: 'Patient’s gender.' },
        patient_mobile: { segment: 'PID', field: 13, subfield: 0, note: 'Patient Mobile', description: 'Patient’s mobile phone number.' },
        patient_email: { segment: 'PID', field: 24, subfield: 0, note: 'Patient Email', description: 'Patient’s email address.' },
        patient_primary_language: { segment: 'PID', field: 15, subfield: 0, note: 'Patient Primary Language', description: 'Patient’s primary language.' }
    });

    const handleInputChange = (e, field) => {
        const { name, value } = e.target;
        setConfig(prevConfig => ({
            ...prevConfig,
            [field]: {
                ...prevConfig[field],
                [name]: value
            }
        }));
    };

    const saveTestConfiguration = async () => {
      try {
          // Transform the config object into the required payload format
          const payload = {
              configuration: Object.keys(config).map(field => {
                  const { segment, field: fieldNumber, subfield } = config[field];
                  return {
                      data_element: field,
                      segment,
                      field: fieldNumber,
                      sub_field: subfield
                  };
              })
          };
          
          await axiosInstance.post('/api/save-test-configuration/', payload);
          // Debugging logs
          console.log('Attempting to navigate to /admin/test-hl7-message');
          navigate('/admin/test-hl7-message');
          console.log('Navigation to /admin/test-hl7-message triggered');
      } catch (error) {
          console.error('Error saving test configuration', error);
      }
    };

    return (
        <div className="container">
            <h2>HL7 Configuration</h2>
            {Object.keys(config).map((field, idx) => (
                <div key={idx} className="row align-items-center mb-3">
                    <div className="col-3">
                        <label>
                            {config[field].note} <br/>
                            <small>{config[field].description}</small>
                        </label>
                    </div>
                    <div className="col-3">
                        <select
                            name="segment"
                            value={config[field].segment}
                            onChange={(e) => handleInputChange(e, field)}
                            >
                            {VALID_HL7_SEGMENTS.map((segment, idx) => (
                                <option key={idx} value={segment}>{segment}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-3">
                        <input 
                            type="number" 
                            name="field" 
                            value={config[field].field} 
                            onChange={(e) => handleInputChange(e, field)} 
                            placeholder="Field" 
                        />
                    </div>
                    <div className="col-3">
                        <input 
                            type="number" 
                            name="subfield" 
                            value={config[field].subfield} 
                            onChange={(e) => handleInputChange(e, field)} 
                            placeholder="Subfield" 
                        />
                    </div>
                </div>
            ))}
            <button className="btn btn-primary" onClick={saveTestConfiguration}>Save Test Configuration</button>
        </div>
    );
};

export default HL7Configuration;