import React, { useState } from 'react';
import axiosInstance from '../utils/auth';
import { useParams, useNavigate } from 'react-router-dom';

const UserRegistration = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const response = await axiosInstance.post(`/api/set-password/${userId}/`, {
        password: password, password_confirm: confirmPassword 
      });

      if (response.status === 200) {
        navigate('/login');
      } else {
        setError('Error setting password');
      }
    } catch (error) {
      setError('Error setting password');
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-sm-10 mx-auto p-0">
          <div className="card p-3">
            <h2>Set Your Password</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="confirmPassword">Confirm Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </div>
              {error && <p className="text-danger">{error}</p>}
              <button type="submit" className="btn btn-primary">
                Set Password
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserRegistration;
